import Axios from './http';
const prefix = '/consumer/v1';

export default class Tester {
  static async check() {
    return Axios(prefix + '/test/checkRecord', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async history(params) {
    return Axios(prefix + '/mine/testHistory', {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      if (res.code === 1) {
        console.log('/mine/testHistory 修改 testid score型別');
        //
        if (res.data?.rows) {
          res.data.rows = res.data.rows.map((x) => {
            return { ...x, testId: x.testId.toString() };
          });
        }
      }
      return res;
    });
  }

  static async list(params) {
    return Axios(prefix + '/test/testId/' + params.testId, {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      console.log('/test/testId 資料重組，id改成string');
      if (res.data?.rows) {
        res.data.rows.forEach((x) => {
          x.userResult = x.userResult !== 0 ? x.userResult : null;
          x.option = x.option.map((x) => {
            return { ...x, id: x.id.toString() };
          });
          x.conf = x.confIndex?.id.toString();
        });
      }

      return res;
    });
  }
  static async save(params) {
    return Axios(prefix + '/test/saveTest', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async helper(params) {
    return Axios(prefix + '/test/helper', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async result(params) {
    return Axios(prefix + '/test/testResult', {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      console.log(
        '/test/testId/result 資料重組，id改成string , result 空的改成null',
      );
      if (res.data?.rows) {
        res.data.rows.forEach((x) => {
          x.userResult = x.userResult !== 0 ? x.userResult : null;
          x.option = x.option.map((x) => {
            return {
              ...x,
              id: x.id.toString(),
            };
          });
          x.conf = x.confIndex?.id.toString();
        });
      }
      return res;
    });
  }
  static async add(params) {
    return Axios(prefix + '/test/addTest', {
      method: 'post',
      responseType: 'json',
      data: { ...params },
    });
  }
  static async memo(params) {
    return Axios(prefix + '/mine/memo/upd', {
      method: 'post',
      responseType: 'json',
      data: { ...params },
    });
  }
}
