import Axios from './http';
const prefix = '/consumer/v1';
export default class User {
  static async checkEmail(params) {
    return Axios(prefix + '/checkEmail', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async codeVerify(params) {
    return Axios(prefix + '/codeVerify', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async checkPhone(params) {
    return Axios(prefix + '/checkPhone', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async delete(params) {
    return Axios(prefix + '/userDelete', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async merge(params) {
    return Axios(prefix + '/userMerge', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async refresh(params) {
    return Axios(prefix + '/tokenRenew', {
      method: 'post',
      responseType: 'json',
    });
  }

  static async codeToken(params) {
    return Axios(prefix + '/google/auth/callback', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async socialLogin(params) {
    return Axios(prefix + '/userSocialLogin', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async status(params) {
    return Axios(prefix + '/authCheck', {
      method: 'get',
      responseType: 'json',
    });
  }

  static async signup(params) {
    return Axios(prefix + '/userSignUp', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async phoneCode(params) {
    return Axios(prefix + '/codeSend', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async login(params) {
    return Axios(prefix + '/userLogin', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async logout(params) {
    return Axios(prefix + '/userLogout', {
      method: 'get',
      responseType: 'json',
    });
  }

  static async forgotCode(params) {
    return Axios(prefix + '/forgetPass', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async forgotCodeVerify(params) {
    return Axios(prefix + '/forgetPassVerify', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async passwordCheck(params) {
    return Axios(prefix + '/checkPass', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async passwordReset(params) {
    return Axios(prefix + '/resetPass', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async profileUpdate(params) {
    return Axios(prefix + '/userInfoUpd', {
      method: 'PATCH',
      responseType: 'json',
      data: params,
    });
  }
  // userImg

  static async avatar(params) {
    return Axios(prefix + '/userImg', {
      method: 'get',
      responseType: 'json',
      // data: params,
    });
  }

  static async AvatarUpdate(params) {
    return Axios(prefix + '/userImgUpd', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async info() {
    return Axios(prefix + '/userInfo', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async changePass(params) {
    return Axios(prefix + '/changePass', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
