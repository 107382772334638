import Axios from './http';
const prefix = '/consumer/v1';
import trans from './_trans';
export default class Question {
  static async add(params) {
    return Axios(prefix + '/exam/', {
      method: 'post',
      responseType: 'json',
    });
  }

  static async detail(params) {
    let query = params.qId;
    if (params.type) {
      query += `?type=${params.type}`;
    }

    return Axios(prefix + '/question/qId/' + query, {
      method: 'get',
      responseType: 'json',
      // params,
    }).then((res) => {
      console.log('question/qId/  資料重組 修改confIndex的直');

      if (res.code === 1) {
        res.data.conf = res.data.confIndex?.id.toString();
      }
      return res;
    });
  }

  // api/consumer/v1/question/preview/Q00000003?token={token}
  static async preview(params) {
    return Axios(prefix + '/question/preview/' + params.qId, {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      if (res.code === 1) {
        console.log('/question/preview 直接修改 option/ status+ correctAns ');

        res.data.ansStatus.status = 'none';
        if (res.data.option.length) {
          res.data.option = res.data.option.map((x) => {
            return {
              ...x,
              status: 'none',
              correctAns: 0,
            };
          });
        }
      }
      return res;
    });
  }

  static async qTypes(params) {
    return Axios(prefix + '/question/qTypes', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async getToken(params) {
    return Axios(prefix + '/question/preview/token?qKey=' + params.qKey, {
      method: 'get',
      responseType: 'json',
      // params,
    });
  }

  static async list(params) {
    return Axios(prefix + '/exam/folder/viewQuestions', {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      if (res.code === 1) {
        trans.questions(res.data);
      }

      return res;
    });
  }
  static async save(params) {
    return Axios(prefix + '/mine/savedQuestion', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async filterId(params) {
    return Axios(prefix + '/mine/question/search', {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      if (res.code === 1) {
        if (res.data.rows) {
          console.log('/mine/question/search 資料重組');
          trans.questions(res.data);
        }
      }
      return res;
    });
  }

  static async result(params) {
    return Axios(prefix + '/mine/question/searchResult', {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      if (res.code === 1) {
        if (res.data.rows) {
          console.log('/searchResult 資料重組');
          trans.questions(res.data);
        }
      }
      return res;
    });
  }
  static async keyword(params) {
    return Axios(prefix + '/filter/keyword/classify', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
