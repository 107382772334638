import { createApp, provide } from 'vue';
import App from './App.vue';
import setRouter from './router/Index';
import 'element-plus/dist/index.css';
import '@/assets/theme.scss';
import { ElButton, ElLoading } from 'element-plus';
import JsonViewer from 'vue-json-viewer';
import FontAwesomeIcon from './fontawesome';
import { svgIcon } from './components/SvgIcon';
import { state, commit, confOpts } from './useStore';
import LoadScript from 'vue-plugin-load-script';
import Storage from '@/utils/Storage';
import useAuth from '@/modules/auth/useAuth2';
import SoftDisabled from '@/utils/directive/SoftDisabled';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueTour from 'v3-tour';
import useTour from '@/modules/tour/useTour';
require('v3-tour/dist/vue-tour.css');

const setupAll = async () => {
  const app = createApp(App);
  app.config.globalProperties.$ELEMENT = {};
  app.component('FontAwesomeIcon', FontAwesomeIcon);
  // app.use(FontAwesomeIcon);
  app.component('SvgIcon', svgIcon);

  app.directive('soft-disabled', SoftDisabled());

  app.component(ElButton);

  app.component('QuillEditor', QuillEditor);

  app.use(ElButton);
  app.use(ElLoading);
  app.use(JsonViewer);
  app.use(LoadScript);
  app.use(VueTour);
  const auth = useAuth();
  window.auth = auth;

  app.provide('auth', auth);

  const version = process.env.VUE_APP_VERSION;
  app.provide('version', Number(version));

  app.provide('tour', useTour());

  if (window.location.pathname === '/token_test') {
    Storage.set(
      'AuthKey',
      'eyJpdiI6IlgwS0xxY2hkTE9EdXh4RE1NWUZnZ0E9PSIsInZhbHVlIjoiT01yZUplaFZMWXNaSVNyUkoyVk5pV2N4alNHeGdBd3FhK2s0UkRnbmcxSXZzTGtLWjU4MCt1STNncEh2TklSYnBQUmh0MUw1RkNhUm8rT3hvdmw5NVE9PSIsIm1hYyI6ImIwMGVlYzU0ZDVlOTlhODFjNjMwOGY4YTdlZmI0MjBhMDI0MDk3MTU2NzkzOTRhZjlhY2IyZTAxM2NlM2QyYTgifQ==',
    );
    return;
  }

  if (!window.location.pathname === '/google/auth/callback') {
    console.log(window.location.pathname);
  } else {
    await auth.status();
  }

  app.provide('store', { state, commit, confOpts });
  const router = setRouter(auth);
  app.use(router);

  app.mount('#app');
};

setupAll();
