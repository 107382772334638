<template>
  <div class="h-full justify-center flex flex-col">
    <h2 class="text-black text-3xl font-bold text-center">密碼修改成功！</h2>
    <el-button type="primary" round class="w-full mt-12" @click="toggleLogin"
      >前往登入畫面</el-button
    >
  </div>
</template>
<script>
import { defineComponent, inject } from 'vue';

export default defineComponent({
  components: {},
  setup() {
    const { commit } = inject('store');

    const toggleLogin = () => {
      commit('state', {
        status: 'isLogining',
      });
    };

    return { toggleLogin };
  },
});
</script>
