import _ from 'lodash';
import Axios from './http';
const prefix = '/consumer/v1';
export default class Exam {
  static async list(params) {
    return Axios(prefix + '/exam/searchResult', {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      if (res.code === 1) {
        console.log('exam/searchResult 資料重組');
        if (!res.data.rows) {
          res.data.rows = [];
        }
        res.data.rows = res.data.rows.map((x) => {
          return {
            ...x,
            saved: !!Number(x.saved),
          };
        });

        res.data.dataType = res.data.dataType.map((x) => {
          return { ...x, id: x.type };
        });
      }
      return res;
    });
  }
  static async recommend(params) {
    return Axios(prefix + '/exam/recommendList', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  static async category(params) {
    return Axios(prefix + '/examCategory', {
      method: 'get',
      responseType: 'json',
    });
  }

  static async title(params) {
    return Axios(prefix + '/examTitle/cKey/' + params.ckey, {
      method: 'get',
      responseType: 'json',
      // params,
    });
  }
  static async filterId(params) {
    return Axios(prefix + '/exam/search', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async mine(params) {
    return Axios(prefix + '/mine/exam', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async addSelf(params) {
    return Axios(prefix + '/exam/addSelfExam', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async save(params) {
    return Axios(prefix + '/mine/savedExam', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async previewQuestions(params) {
    return Axios(prefix + `/exam/emId/${params.emId}`, {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
}
