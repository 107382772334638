<template>
  <El-Form-Item :label="label" :prop="propName" :rules="rules_">
    <El-Input
      v-model="password"
      :show-password="true"
      type="password"
      autocomplete="off"
      placeholder="請輸入密碼"
      @keydown.enter="$emit('onEnter')"
    ></El-Input>
    <p class="text-xs text-gray mt-2">
      請混合使用8個字元以上的英文字母大小寫、數字和符號，且字數不超過16個字元
    </p>
    <!-- TODO: onenter event -->
  </El-Form-Item>
</template>
<script>
import { reactive, ref, toRefs } from 'vue';
import { ElFormItem, ElInput } from 'element-plus';
import useComputed from '@/hook/useComputed';
export default {
  components: {
    ElFormItem,
    ElInput,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    propName: {
      type: String,
      default: 'password',
    },
    strong: {
      type: Boolean,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'onEnter'],
  setup(props, { emit }) {
    const { modelValue, rules, strong } = toRefs(props);
    const isValid = ref(true);
    const password = useComputed(modelValue, emit);

    // /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/

    const regex = new RegExp(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[1-9#?!@$%^&*-]).{8,16}$/,
    );
    const rules_ = reactive([
      // {
      //   // required: true,
      //   message: '未填寫內容',
      //   trigger: 'blur',
      // },
      {
        min: 8,
        message: '字數不足-最少須有8個字元。',
        trigger: 'blur',
      },
      {
        max: 16,
        message: '字數超過上限-字數已超過上限16個字元。',
        trigger: 'blur',
      },
    ]);

    if (strong.value) {
      rules_.push({
        validator: (rule, value, callback) => {
          if (!regex.test(value)) {
            callback(new Error('需有英文字母大小寫+數字或符號。'));
          } else {
            callback();
          }
        },
        trigger: 'blur',
      });
    }
    if (rules.value) {
      rules_.push(...rules.value);
    }

    return { password, isValid, rules_ };
  },
};
</script>
