import { ref } from 'vue';

import { tryOnScopeDispose, useTimeoutFn } from '@vueuse/shared';

export default function useTimeoutPoll(fn, interval, timeoutPollOptions) {
  const { start } = useTimeoutFn(loop, interval);

  const isActive = ref(false);

  async function loop() {
    if (!isActive.value) return;

    await fn();
    start();
  }

  function resume() {
    if (!isActive.value) {
      isActive.value = true;
      loop();
    }
  }

  function pause() {
    isActive.value = false;
  }

  if (timeoutPollOptions?.immediate) resume();

  tryOnScopeDispose(pause);

  return {
    isActive,
    pause,
    resume,
  };
}
