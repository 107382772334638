import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/home/Index.vue';
import notFound from '@/views/404.vue';
import signup from './signup';
import assistant from './assistant';
import account from './account';
import { commit } from '@/useStore';

import { cloneDeep } from 'lodash';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {},
  },
  {
    path: '/question/preview/:qId',
    name: 'question.preview',
    meta: {
      header: false,
      footer: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "question.preview" */ '../views/preview/Question.vue'
      ),
  },
  {
    path: '/n/preview/:id',
    name: 'note.preview',
    meta: {
      header: false,
      footer: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "note.preview" */ '../views/preview/Note.vue'
      ),
  },
  {
    path: '/about',
    name: 'About',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Privacy" */ '../views/page/Privacy.vue'),
  },
  {
    path: '/copyright',
    name: 'Copyright',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Copyright" */ '../views/page/Copyright.vue'),
  },
  {
    path: '/consumer',
    name: 'Consumer',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Consumer" */ '../views/page/Consumer.vue'),
  },
  {
    path: '/term',
    name: 'Term',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "Copyright" */ '../views/page/Term.vue'),
  },
  {
    path: '/news',
    name: 'News.List',
    component: () =>
      import(/* webpackChunkName: "News.List" */ '../views/news/index.vue'),
  },
  {
    path: '/news/:id',
    name: 'News.Show',
    component: () =>
      import(/* webpackChunkName: "News.Show" */ '../views/news/single.vue'),
  },
  {
    path: '/note',
    name: 'Note',
    redirect: '/note/list',
    component: () =>
      import(/* webpackChunkName: "Note.List" */ '../views/note/Index.vue'),
    children: [
      {
        path: 'list',
        name: 'Note.List',
        component: () =>
          import(/* webpackChunkName: "Note.List" */ '../views/note/List.vue'),
      },
      {
        path: ':id',
        name: 'Note.Show',
        component: () =>
          import(/* webpackChunkName: "Note.Show" */ '../views/note/Show.vue'),
      },
    ],
  },

  {
    path: '/preference',
    name: 'Preference',
    meta: {
      footer: false,
    },
    component: () =>
      import(/* webpackChunkName: "Preference" */ '../views/Prefer.vue'),
  },
  {
    path: '/shop',
    name: 'Shop',
    meta: {},
    redirect: '/shop/home',
    component: () =>
      import(/* webpackChunkName: "Shop" */ '../views/shop/Index.vue'),
    children: [
      {
        path: 'home',
        name: 'Shop.Home',
        meta: {
          footer: true,
        },
        component: () =>
          import(/* webpackChunkName: "Shop.Home" */ '../views/shop/Home.vue'),
      },
      {
        path: 'plan',
        name: 'Shop.Plan',
        meta: {
          footer: false,
        },
        component: () =>
          import(/* webpackChunkName: "Shop.Plan" */ '../views/shop/Plan.vue'),
      },
      {
        path: 'checkout',
        name: 'Shop.Checkout',
        meta: {
          footer: true,
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "Shop.Checkout" */ '../views/shop/Checkout.vue'
          ),
      },
    ],
  },
  {
    path: '/checkout/waiting',
    name: 'Checkout.Waiting',
    meta: {
      footer: true,
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "Checkout.Waiting" */ '../views/shop/Waiting.vue'
      ),
  },
  {
    path: '/analyze',
    name: 'Analyze',
    meta: {
      auth: true,
    },

    component: () =>
      import(/* webpackChunkName: "Analyze" */ '../views/analyze/Index.vue'),
  },
  assistant,
  account,
  {
    path: '/tester/:testId',
    name: 'Tester',
    meta: {
      footer: true,
    },
    component: () =>
      import(/* webpackChunkName: "Tester" */ '../views/tester/Tester.vue'),
  },
  {
    path: '/tester/:testId/result',
    name: 'Tester.Result',
    meta: {
      footer: true,
      noContextmenu: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "Tester.Result" */ '../views/tester/Result.vue'
      ),
  },
  {
    path: '/exam/:slug',
    name: 'Exam', // QB題庫,
    meta: {},

    component: () =>
      import(/* webpackChunkName: "Exam" */ '../views/exam/Index.vue'),
    children: [
      {
        path: 'filter',
        name: 'Exam.Filter',
        meta: {
          footer: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "Exam.filter" */ '../views/exam/Filter.vue'
          ),
      },
      {
        path: 'list',
        name: 'Exam.List',
        meta: {
          footer: true,
        },
        component: () =>
          import(/* webpackChunkName: "Exam.list" */ '../views/exam/List.vue'),
      },
      {
        path: 'question',
        name: 'Exam.Question',
        meta: {
          footer: true,
          noContextmenu: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "Exam.Question" */ '../views/exam/Question.vue'
          ),
      },
    ],
  },
  signup,
  {
    path: '/google/auth/callback',
    name: 'google.auth.callback',
    component: () =>
      import(
        /* webpackChunkName: "Auth.Google" */ '../modules/auth/google/Callback.vue'
      ),
  },

  {
    path: '/qb/nonauth/checkout',
    name: 'Qb.Checkout',
    meta: {
      footer: true,
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "Qb.Checkout" */ '../views/qb/Checkout.vue'),
  },
  {
    path: '/qb/nonauth/waiting',
    name: 'Qb.Waiting',
    meta: {
      footer: true,
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "Qb.Waiting" */ '../views/qb/Waiting.vue'),
  },
  { path: '/:path(.*)', component: notFound },
];

export default (auth) => {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { top: 0 };
    },
  });
  const google = async (to, next) => {
    //   const publicPages = ['staff.signin', 'customer.signin'];
    //   const authRequired = !publicPages.includes(to.name);
  };

  const guard = (to, next) => {
    //   const publicPages = ['staff.signin', 'customer.signin'];
    //   const authRequired = !publicPages.includes(to.name);

    if (!auth.state.isAuth && to.meta.auth) {
      commit('state', { status: 'isLogining' });
    } else {
      next();
    }
  };

  router.beforeEach((to, from, next) => {
    if (to.name !== from.name) {
      commit('state', {
        prev: {
          params: { ...from.params },
          name: from.name,
          query: { ...from.query },
        },
      });
    }

    // if (to.name !== from.name && to.matched.some((record) => (record.meta.hasQueryParamPage))) {

    //   let updatedNext = null;

    //   if (to.query?.pg && to.query.pg != 1) {
    //     updatedNext = {
    //       ...to,
    //       query: {
    //         // This line is used to retain other query if there is any
    //         ...to.query,
    //         pg: 1,
    //       },
    //     };
    //   }

    //   if (updatedNext) {
    //     next(updatedNext);
    //     return;
    //   }
    // }

    guard(to, next);
  });
  return router;
};
