<template>
  <div class="shadow-md w-full fixed top-0 bg-white z-50 h-[64px]">
    <div class="container flex items-center w-full h-full">
      <div class="brand">
        <router-link to="/">
          <img src="@/assets/imgs/logo.png" alt="" class="md:w-2/3"
        /></router-link>
      </div>

      <Menu class="ml-3 h-full flex-grow md:hidden"></Menu>
      <div class="ml-auto space-x-4 flex items-center">
        <ContinueTester type="primary"></ContinueTester>

        <div class="flex items-center space-x-4">
          <el-button :icon="Search" circle @click="toggleSearch"></el-button>
          <SearchBar v-show="isSearching"></SearchBar>
        </div>
        <ElButton v-if="!auth.state.isAuth" round @click="toggleLogin">
          登入
        </ElButton>
        <ElButton
          v-if="!auth.state.isAuth"
          type="primary"
          round
          @click="$router.push({ name: 'Signup.basic' })"
        >
          免費加入
        </ElButton>
        <template v-if="auth.state.isAuth">
          <!-- <a class="ml-2" href="#" @click.prevent="logout">登出</a> -->
          <el-dropdown>
            <div class="flex items-center">
              <div
                class="avatar w-8 h-8 aspect-square rounded-full overflow-hidden"
              >
                <img
                  v-if="auth.user.imgUrl"
                  class="w-full h-full object-cover"
                  :src="auth.user.imgUrl"
                />
                <img v-else src="@/assets/imgs/profile_photo.png" alt="" />
              </div>
              <div class="ml-2 md:hidden">
                Hi,{{ auth.user.name || 'User' }}
              </div>
              <el-icon class="el-icon--right md:hidden">
                <arrow-down />
              </el-icon>
            </div>
            <template #dropdown>
              <div v-if="auth.user.status" class="flex flex-col mt-4">
                <div
                  v-for="status in auth.user.status"
                  :key="status"
                  class="whitespace-nowrap bg-premium rounded py-1 px-3 text-xs mx-3 text-white flex items-center mb-1"
                  :style="{ background: PACKAGE_NAMES[status]?.color }"
                >
                  <!-- 之後要加上不同的icon? -->
                  <font-awesome-icon
                    v-if="status !== 'UnPaid'"
                    :icon="['fas', 'crown']"
                    class="mr-1 text-yellow"
                  />
                  {{ PACKAGE_NAMES[status]?.name }}
                </div>
              </div>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="router.push({ path: '/account/home' })"
                  >我的帳號</el-dropdown-item
                >
                <el-dropdown-item
                  @click="router.push({ path: '/account/history' })"
                  >會員方案紀錄</el-dropdown-item
                >
                <el-dropdown-item @click="dialogRedeem.toggle({ show: true })">
                  QB線上題庫啟用
                </el-dropdown-item>
                <el-dropdown-item
                  @click="router.push({ path: '/account/contact' })"
                  >聯絡我們</el-dropdown-item
                >
                <el-dropdown-item @click.prevent="logout"
                  >登出</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>

        <font-awesome-icon
          :icon="['fas', 'bars']"
          class="text-3xl cursor-pointer hidden md:block"
          @click="menuDrawer = true"
        />
      </div>
    </div>
    <el-drawer
      v-model="menuDrawer"
      direction="rtl"
      :with-header="false"
      size="80%"
    >
      <Menu mode="vertical" @close="closeDrawer"></Menu>
    </el-drawer>

    <el-dialog
      v-model="dialogRedeem.state.show"
      custom-class="md:w-[94%] w-[360px]"
      :destroy-on-close="true"
    >
      <Redeem
        :title="'QB線上題庫啟用'"
        :info="'請輸入題庫啟用碼 <br /> 立即啟用該科目線上題庫！'"
        @close="dialogRedeem.toggle({ show: false })"
      ></Redeem>
    </el-dialog>
  </div>
</template>

<script setup>
import Menu from './Menu.vue';
import { Search } from '@element-plus/icons';
import ContinueTester from '@/modules/tester/record/BtnContinue';
import { PACKAGE_NAMES } from '@/utils/Config';
import {
  ElDrawer,
  ElDropdown,
  ElDropdownItem,
  ElIcon,
  ElDropdownMenu,
} from 'element-plus';
import { ArrowDown } from '@element-plus/icons-vue';
import SearchBar from '@/modules/search/Index';
import { inject, ref } from 'vue';
import { useRouter } from 'vue-router';

import useDialog from '@/hook/useDialog';
import { ElDialog } from 'element-plus';
import Redeem from '@/modules/coupon/redeem/index.vue';

const { commit } = inject('store');
const auth = inject('auth');

const router = useRouter();

const dialogRedeem = useDialog();

const toggleLogin = () => {
  commit('state', {
    status: 'isLogining',
  });
};

const menuDrawer = ref(false);
const logout = () => {
  auth.logout().then((res) => {
    // router.replace({ name: 'Home' });
    window.location.href = '/';
  });
};

const closeDrawer = (e) => {
  console.log('closeDrawer: ');
  menuDrawer.value = false;
};
const isSearching = ref(false);
const toggleSearch = () => {
  isSearching.value = !isSearching.value;
};
</script>
