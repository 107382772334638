import MessageBox from '@/components/messageBox/Index';
import { h } from 'vue';
export default async () => {
  return await MessageBox.confirm('', '', 'success', {
    showClose: false,
    showCancelButton: true,
    closOnClickModal: false,
    message: h('div', null, [
      h('div', { class: 'text-2xl font-bold mt-2' }, '提醒'),
      h(
        'div',
        { class: 'mt-3 text-left' },
        '1.系統會自動移除您尚未購買的科目試題。',
      ),
      h(
        'div',
        { class: 'mt-3 text-left' },
        '2.若此份篩選中有題組，可能會使總題數與設定題數不一致。',
      ),
      h('div', { class: 'mt-3 text-left' }, '3.出題順序不等於列表順序。'),
    ]),
  });
};
