import Axios from './http';
const prefix = '/consumer/v1';

export default class Conf {
  static async list() {
    return Axios(prefix + '/confIndex', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async save(params) {
    return Axios(prefix + '/mine/saveConfIndex', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
