import Axios from './http';
const prefix = '/consumer/v1';
export default class News {
  static async latest(params) {
    return Axios(prefix + '/newsList', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async list() {
    return Axios(prefix + '/newsList', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async show(params) {
    return Axios(prefix + '/newsDetail', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
}
