<template>
  <div class="grid grid-cols-3 gap-4 md:grid-cols-1">
    <div
      v-for="row in rows"
      :key="row.id"
      class="bg-white rounded px-4 py-3 shadow-md duration-300 hover:shadow-xl cursor-pointer"
      @click="$router.push({ name: 'News.Show', params: { id: row.id } })"
    >
      <img :src="row.imageUrl" alt class="w-full" @error="loadImgError" />
      <div class="mt-4">
        <div class="flex items-center">
          <div>{{ row.date }}</div>
          <div class="flex ml-2">
            <div
              v-for="(item, index) in row.types"
              :key="index"
              class="mx-1 rounded-full text-sm text-white bg-info px-3 py-1"
              :style="{ backgroundColor: item.color }"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <p class="pt-4 pb-8">
          {{ row.text }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import News from '@/api/News';
import { reactive } from 'vue';
export default {
  setup() {
    const rows = reactive([]);
    News.latest().then((res) => {
      if (res.code === 1) {
        rows.splice(0, 0, ...res.data.rows);
      }
    });

    const loadImgError = (e) => {
      e.currentTarget.src = require(`@/assets/imgs/news-demo.png`);
    };
    return { rows, loadImgError };
  },
};
</script>
