import _ from 'lodash';
const prefix = 'jody_';
const storage = () => {
  const set = (key, value) => {
    localStorage.setItem(prefix + key, JSON.stringify(value));
  };
  const get = (key) => {
    const value = localStorage.getItem(prefix + key);

    return value ? JSON.parse(value) : null;
  };
  const remove = (key) => {
    localStorage.removeItem(prefix + key);
  };

  const clear = (key) => {
    localStorage.clear();
  };

  const sync = (key, _form) => {
    const data = get(key);
    if (data) {
      Object.keys(data).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(_form, key)) {
          _form[key] = data[key];
        }
      });
      // Object.assign(from, data);
    }
  };

  return {
    set,
    get,
    remove,
    clear,
    sync,
  };
};
export default { ...storage() };
