<template>
  <h2 class="text-3xl text-primary font-bold">忘記密碼？</h2>
  <p class="mt-4 text-gray">重設驗證碼將寄送至您的手機，請輸入您的手機號碼。</p>
  <El-Form
    ref="formRef"
    v-loading="loading"
    :model="form"
    :rules="rules"
    class="mt-6"
  >
    <Phone v-model="form.phone" class="error-right" :disabled="countdown > 0">
      <template #suffix="slot">
        <el-button
          :loading="requiring"
          size="small"
          round
          :disabled="!slot.valid || countdown > 0"
          type="primary"
          @click="requireCode({ phone: form.phone })"
        >
          <span v-if="countdown > 0"
            >已寄送密碼連結，可於{{ countdown }}秒後再次寄送</span
          >
          <span v-else>寄送密碼連結</span>
        </el-button>
      </template>
    </Phone>

    <El-Form-item
      prop="code"
      :rules="{
        required: true,
        message: '未填寫內容',
      }"
    >
      <div class="">
        <El-Input
          v-model="form.code"
          :disabled="!countdown"
          placeholder="輸入驗證碼"
        ></El-Input>
      </div>
    </El-Form-item>

    <El-Form-item>
      <el-button :disabled="!form.code" type="primary" @click="onSubmit"
        >確認，前往重設密碼</el-button
      >
    </El-Form-item>
  </El-Form>
</template>

<script>
import { ref, defineComponent, inject } from 'vue';
import { ElFormItem, ElForm, ElInput } from 'element-plus';
import Phone from '@/components/form/Phone.vue';
import useChecker from '@/modules/auth/useChecker';
import User from '@/api/User';
export default defineComponent({
  components: {
    ElFormItem,
    ElForm,
    ElInput,
    Phone,
  },

  setup() {
    const formRef = ref(null);

    const { form, verify, loading } = inject('forgot');

    const {
      submit: requireCode,
      countdown,
      loading: requiring,
    } = useChecker(User.forgotCode);

    const rules = {
      code: [],
    };

    const onSubmit = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          verify();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };
    return {
      onSubmit,
      requireCode,
      form,
      rules,
      formRef,
      countdown,
      requiring,
      loading,
    };
  },
});
</script>
