import { reactive } from 'vue';

export default (attrs) => {
  const state = reactive({
    show: false,
    component: null,
    attrs: { width: '96%', top: '2rem', ...attrs },
  });
  const toggle = (params) => {
    if (params && params.show) {
      state.component = params.component;
      state.attrs = { ...state.attrs, ...params.attrs };
      state.show = true;
    } else {
      state.show = false;
      state.component = null;
      // state.attrs = {};
    }
  };

  return {
    toggle,
    state,
  };
};
