export default {
  path: '/assistant',
  name: 'Assistant',
  meta: {
    footer: true,
    auth: true,
  },
  component: () =>
    import(/* webpackChunkName: "Assistant" */ '../views/assistant/Index.vue'),
  redirect: '/assistant/home',
  children: [
    {
      path: 'home',
      name: 'Assistant.Home',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Assistant.Home" */ '../views/assistant/Home.vue'
        ),
    },
    {
      path: 'tester',
      name: 'Assistant.Tester',
      meta: {
        footer: true,
        noContextmenu: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Assistant.Tester" */ '../views/assistant/Tester.vue'
        ),
    },
    {
      path: 'wrong',
      name: 'Assistant.Wrong',
      meta: {
        footer: true,
        noContextmenu: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Assistant.Wrong" */ '../views/assistant/Wrong.vue'
        ),
    },
    {
      path: 'collect',
      name: 'Assistant.Collect',
      meta: {
        footer: true,
        noContextmenu: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Assistant.Collect" */ '../views/assistant/Collect.vue'
        ),
    },
    {
      path: 'like',
      name: 'Assistant.Like',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Assistant.Like" */ '../views/assistant/Like.vue'
        ),
    },
    {
      path: 'question',
      name: 'Assistant.Question',
      meta: {
        footer: true,
        noContextmenu: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Assistant.Question" */ '../views/assistant/Question.vue'
        ),
    },
    {
      path: 'memo',
      name: 'Assistant.Memo',
      meta: {
        footer: true,
        noContextmenu: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Assistant.Memo" */ '../views/assistant/Memo.vue'
        ),
    },
    {
      path: 'target/set',
      name: 'Assistant.target',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Assistant.statistic" */ '../views/assistant/SetTarget.vue'
        ),
    },
  ],
};
