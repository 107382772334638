<template>
  <div class="flex py-8">
    <div class="w-1/2 flex picWrap md:hidden">
      <img
        v-if="active === 'code'"
        src="@/assets/imgs/jody-mic.png"
        alt=""
        class="pic-role"
      />
      <img v-else src="@/assets/imgs/avatar-big.png" alt="" class="pic-role" />
    </div>
    <div class="w-1/2 px-12 md:w-full">
      <template v-if="active === 'code'">
        <Code />
      </template>
      <template v-if="active === 'reset'">
        <Reset />
      </template>
      <template v-if="active === 'success'">
        <Success />
      </template>
    </div>
  </div>
</template>
<script>
import Code from './Code.vue';
import Reset from './Reset.vue';
import Success from './Success.vue';
import { provide } from 'vue';
import useForgot from './useForgot';

export default {
  components: {
    Code,
    Reset,
    Success,
  },
  setup(props) {
    const forgot = useForgot();
    provide('forgot', forgot);
    forgot.setActive('code');
    return { active: forgot.active };
  },
};
</script>

<style lang="sass" scoped>
.picWrap
  justify-content: center
  align-items: center
  position: relative

  .pic-role
    position: relative
    z-index: 2
</style>
