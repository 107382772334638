<template>
  <div>
    <div class="text-center font-bold text-lg">{{ title }}</div>

    <div v-if="auth.state.isAuth" v-loading="loading">
      <div class="item-center flex mt-4">
        <ElInput
          v-model.trim="form.code"
          placeholder="請輸入折扣代碼"
          @focus="errorMsg = ''"
        >
          <template #append>
            <el-button :disabled="!form.code" type="primary" @click="submit"
              >兌換</el-button
            >
          </template>
        </ElInput>
      </div>

      <p v-if="errorMsg" class="text-danger mt-2">
        {{ errorMsg }}
      </p>

      <p class="mt-4 text-center" v-html="info"></p>
      <div class="flex justify-center">
        <img src="@/assets/imgs/role5.png" alt="" class="w-24 mt-4" />
      </div>
    </div>
    <p v-else class="mt-4 py-4 text-center border-t">請先登入會員</p>
    <el-dialog
      v-model="dialog.state.show"
      custom-class="md:w-[94%] w-[800px]"
      :destroy-on-close="true"
    >
      <Info
        :row="row"
        :code="form.code"
        @cancel="dialog.toggle({ show: false })"
        @success="closeAll"
      ></Info>
    </el-dialog>
  </div>
</template>
<script setup>
import { reactive, ref, inject, defineEmits, defineProps } from 'vue';
import { ElInput, ElDialog } from 'element-plus';
import Payment from '@/api/Payment.js';
import MessageBox from '@/components/messageBox/Index';
import useDialog from '@/hook/useDialog';
import Info from './_Info.vue';
const auth = inject('auth');

const loading = ref(null);
const form = reactive({ code: '' });
const row = reactive({});
const errorMsg = ref('');
const dialog = useDialog();

const submit = () => {
  loading.value = true;
  return Payment.redeem({ code: form.code })
    .then(async (res) => {
      loading.value = false;
      if (res.data?.errorCode) {
        errorMsg.value = res.data.message;
      } else {
        Object.assign(row, res.data);
        try {
          if (res.paidQB === 1) {
            await MessageBox.confirm(
              '方案重疊',
              `您目前已有吃到飽全科目方案正在進行中,\n是否確定要兌換"${res.data.content.subject}"單一科目試題方案?`,
              'alert',
            );
          }

          dialog.toggle({ show: true });
        } catch (error) {
          console.log(error);
        }
      }
    })
    .finally((res) => {
      loading.value = false;
      return res;
    });
};
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  info: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['close']);
const closeAll = (params) => {
  dialog.toggle({ show: false });
  emit('close');
};
</script>
