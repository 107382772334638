import User from '@/api/User';
import Prefer from '@/api/Prefer.js';
import { ElMessage } from 'element-plus';
import MessageBox from '@/components/messageBox/Index';

import { ref, reactive, inject } from 'vue';
import Regex from '@/utils/Regex';
import { useRoute, useRouter } from 'vue-router';

export default () => {
  const loading = ref(false);
  const { commit } = inject('store');
  const auth = inject('auth');

  const router = useRouter();

  const codeToken = async (form) => {
    const googleCode = await User.codeToken({ ...form });
    return googleCode;
  };

  const merge = async ({ token }) => {
    try {
      const confirm = await MessageBox.confirm(
        '帳號已經存在，是否要連結您的帳號',
        '',
        'success',
      );
      if (confirm) {
        const merge = await User.merge({ token });
        ElMessage('成功連結帳號');
        return merge;
      }
    } catch (error) {
      commit('state', {
        status: 'isLogining',
      });
    }
    return;
  };

  const verify = async (form) => {
    const res = await User.codeVerify({ ...form });
    return res;
  };

  const signin = async (form) => {
    const res = await User.socialLogin({ ...form });

    if (res.code === 1 && res.data.duplicate === '1') {
      ElMessage({
        message: '成功登入，並結束其他裝置的登入狀態',
        type: 'success',
      });
    } else if (res.code === 1) {
      ElMessage({ message: '成功登入', type: 'success' });
    }

    commit('state', { status: false });

    const preferRes = await Prefer.check();
    // 結果都回200
    if (preferRes.code === 1 && preferRes.status === 204) {
      router.push({ name: 'Preference' });
    } else {
      router.push({ name: 'Assistant' });
    }

    return res;
  };

  const sign = async (response) => {
    const codeTokenRes = await codeToken({ ...response });

    const { needMerge, token, verifiedPhone, email } = codeTokenRes.data;
    if (codeTokenRes.code !== 1) {
      ElMessage('認證失敗');
      return;
    }

    // 單純連結 所以不需要後續
    if (needMerge === '1') {
      await merge({ token });
      const signinRes = await signin({ token });
      if (signinRes.code === 1) {
        const { accountId, duplicate, imgUrl, name, status } = signinRes.data;
        auth.setAuth(signinRes.data);
      }

      // 沒有註冊過 所以需要跑完整流程驗證
    } else if (verifiedPhone === '0') {
      router.push({ name: 'Signup.verify', query: { email, token } });

      // 表示甚麼都做過了  單純登入
    } else if (verifiedPhone === '1') {
      const signinRes = await signin({ token });
      if (signinRes.code === 1) {
        auth.setAuth(signinRes.data);
      }
    }
    commit('state', { status: false });
  };

  return {
    loading,
    sign,
    signin,
    status,
    merge,
    verify,
    // type,
  };
};
