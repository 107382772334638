import User from '@/api/User';
import { ref, reactive, inject } from 'vue';
import Storage from '@/utils/Storage';
import _ from 'lodash';
import useTimeoutPoll from '@/utils/useTimeoutPoll';
import { commit } from '@/useStore';
const loading = ref(false);
export default () => {
  const state = reactive({
    isAuth: false,
    AuthKey: null,
  });
  const user = reactive({
    accountId: null,
    duplicate: 0,
    imgUrl: null,
    name: null,
    status: null,
    email: null,
    accountType: 'web',
  });

  const logout = () => {
    loading.value = true;
    return User.logout()
      .then((res) => {
        // ElMessage({ message: '會員登出' });
        clear();
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const set = (authData) => {
    state.isAuth = authData.isAuth;
    state.AuthKey = authData.AuthKey;
    if (!authData?.user) {
      Object.keys(user).forEach((key) => {
        user[key] = '';
      });
    } else {
      let status = [];
      if (authData.user.status) {
        status = _.isString(authData.user.status)
          ? [authData.user.status]
          : Object.keys(authData.user.status);
      }
      if (!status?.length) {
        status = ['UnPaid'];
      }
      Object.assign(user, { ...authData.user, status });
    }

    if (!state.isAuth) {
      Storage.remove('AuthKey');
      return;
    }

    if (state.AuthKey) {
      Storage.set('AuthKey', state.AuthKey);
    }
  };

  const check = () => {
    User.status().then((res) => {
      if (res.code === 1) {
        setAuth(res.data);
      } else {
        checker.pause();
      }
    });
  };
  const checker = useTimeoutPoll(check, 1000 * 60 * 10);

  const clear = (authData) => {
    set({});
    checker.pause();
  };

  const setAuth = (authData) => {
    const { accountId, duplicate, imgUrl, name, status, email, accountType } =
      authData;
    set({
      isAuth: true,
      AuthKey: authData.AuthKey,
      user: { accountId, duplicate, imgUrl, name, status, email, accountType },
    });
    checker.resume();
  };
  const setUserData = (userData) => {
    Object.assign(user, { ...userData });
  };

  const guard = () => {
    if (!state.isAuth) {
      commit('state', { status: 'isLogining' });
      return false;
    }
    return true;
  };

  const status = () => {
    const AuthKey = Storage.get('AuthKey');
    return new Promise((resolve, reject) => {
      if (!AuthKey) {
        resolve(false);
      } else {
        state.isAuth = true;
        loading.value = true;
        User.status()
          .then((res) => {
            if (res.code === 1) {
              setAuth(res.data);
            }
            resolve(res);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    });
  };

  return { status, state, user, logout, setAuth, guard, setUserData, clear };
};
