import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHashtag,
  faAlignJustify,
  faBars,
  faCheck,
  faGripHorizontal,
  faPhone,
  faPlus,
  faHistory,
  faHeart as fasHeart,
  faCaretDown,
  faTimesCircle,
  faArrowLeft,
  faArrowRight,
  faReply,
  faPen,
  faStar,
  faTimes,
  faTrash,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faMicrophone,
  faBookmark,
  faPlay,
  faPause,
  faGripVertical,
  faGripLinesVertical,
  faEnvelope,
  faMinus,
  faCommentAlt,
  faClipboard,
  faQuestion,
  faScroll,
  faBook,
  faChevronLeft,
  faChevronDown,
  faArrowDown,
  faArrowUp,
  faAngleDown,
  faAngleUp,
  faQuestionCircle,
  faCreditCard,
  faTicketAlt,
  faUser,
  faCrown,
  faSpinner,
  faClock,
  faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
  faTimesCircle as farTimesCircle,
  faHeart as farHeart,
  faCheckCircle as farCheckCircle,
  faMeh as farMeh,
  faBookmark as farBookmark,
  faEnvelope as farEnvelope,
  faClipboard as farClipboard,
} from '@fortawesome/free-regular-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

library.add(
  faFacebookSquare,
  faHashtag,
  faClock,
  faChevronLeft,
  faUser,
  faHistory,
  faCrown,
  faTicketAlt,
  faCreditCard,
  faChevronDown,
  faScroll,
  faQuestion,
  faQuestionCircle,
  faMinus,
  farEnvelope,
  faEnvelope,
  faPause,
  faBookmark,
  faBook,
  farBookmark,
  faPhone,
  faClipboard,
  farClipboard,
  faGripHorizontal,
  faGripLinesVertical,
  faGripVertical,
  faPlay,
  faAlignJustify,
  faBars,
  faPlay,
  faPlus,
  faMicrophone,
  faCheck,
  fasHeart,
  farHeart,
  farMeh,
  faCaretDown,
  faTimesCircle,
  farTimesCircle,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faAngleDown,
  faAngleUp,
  faArrowUp,
  faReply,
  faPen,
  faStar,
  faTimes,
  faTrash,
  faCommentAlt,
  faSpinner,
  faCheckCircle,
  farCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faRedoAlt,
);

export default FontAwesomeIcon;
