import { ElMessage } from 'element-plus';

import { ref, reactive, onUnmounted } from 'vue';

export default (API) => {
  const defaultCountdown = 60;
  const loading = ref(false);
  const countdown = ref(0);

  let counter;
  const reset = () => {
    if (counter) {
      clearInterval(counter);
      countdown.value = 60;
    }
  };

  const submit = (param) => {
    loading.value = true;

    return API({ ...param })
      .then((res) => {
        // 缺少error code
        if (res.errorCode) {
          ElMessage(res.message);
        } else {
          if (res.code === 1) {
            ElMessage({ message: '成功取得', type: 'success' });
            countdown.value = 60;
            if (counter) {
              clearInterval(counter);
            }
            counter = setInterval(() => {
              countdown.value -= 1;
              if (countdown.value <= 0) {
                clearInterval(counter);
                countdown.value = 0;
              }
            }, 1000);
          } else {
            console.log(res);
            ElMessage({ message: '驗證碼取得失敗', type: 'error' });
          }
        }
        return res;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  onUnmounted(() => clearInterval(counter));
  return {
    loading,
    countdown,
    submit,
    reset,
  };
};
