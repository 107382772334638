<template>
  <div v-if="rows.length && rows.length === 1" class="flex w-full">
    <a :href="rows[0].redirectUrl">
      <img :src="rows[0].imageUrl" class="w-full md:hidden" />
      <img :src="rows[0].mobileImageUrl" class="w-full hidden md:block" />
    </a>
  </div>
  <swiper
    v-else-if="rows.length"
    :modules="modules"
    :slides-per-view="1"
    :space-between="0"
    :navigation="false"
    :loop="true"
    :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }"
    :pagination="{ clickable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="row in rows" :key="row.id">
      <a :href="row.redirectUrl">
        <img :src="row.imageUrl" class="w-full md:hidden" />
        <img :src="row.mobileImageUrl" class="w-full hidden md:block" />
      </a>
    </swiper-slide>
  </swiper>
</template>
<script>
import { reactive, computed } from 'vue';
import Banner from '@/api/Banner';
import { Autoplay, Navigation, Pagination } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

import 'swiper/swiper-bundle.css';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const rows = reactive([]);
    Banner.list().then((res) => {
      if (res.code === 1) {
        // res.data.rows.splice(0, 1);
        rows.push(...res.data.rows);
      }
    });
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {
      console.log('slide change');
    };
    const loop = computed((params) => {
      return rows.length > 1;
    });
    return {
      rows,
      loop,
      onSwiper,
      onSlideChange,
      modules: [Autoplay, Navigation, Pagination],
    };
  },
};
</script>
<style lang="scss" scoped>
:deep() {
  .swiper-button-prev {
    left: 20px;
  }
  .swiper-button-next {
    right: 20px;
  }
}
</style>
