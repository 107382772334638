import Axios from './http';
const prefix = '/consumer/v1';
export default class Prefer {
  static async check(params) {
    return Axios(prefix + '/preferCheck', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async list(params) {
    return Axios(prefix + '/preferList', {
      method: 'get',
      responseType: 'json',
    }).then((res) => {
      return res;
    });
  }
  static async record(params) {
    return Axios(prefix + '/preferRecord', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
