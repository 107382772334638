<template>
  <div class="flex py-8">
    <div class="w-1/2 flex picWrap md:hidden">
      <img src="@/assets/imgs/deco.png" alt="" class="pic-bg" />
      <img src="@/assets/imgs/jody-signin.png" alt="" class="pic-role" />
    </div>
    <div class="w-1/2 px-12 md:w-full">
      <h2 class="text-primary text-4xl mb-6 text-center">JODY會員登入</h2>
      <El-Form ref="formRef" v-loading="loading" :model="form">
        <Account v-model="form.account"></Account>
        <Password v-model="form.password" @onEnter="onSubmit" />
        <El-Form-item>
          <El-button type="primary" class="w-full" round @click="onSubmit"
            >登入</El-button
          >
        </El-Form-item>
        <!-- 這個可以統一改 button type="text" -->
        <p class="cursor-pointer underline text-center" @click="toForgot">
          忘記密碼
        </p>
      </El-Form>
      <hr class="mt-4" />
      <div>
        <p class="my-4 text-center">或使用以下方式登入：</p>
        <Google class="w-full"></Google>
        <!-- 這個可以統一改 button type="text" -->
        <p class="mt-4 underline cursor-pointer text-center" @click="toSignup">
          還不是會員嗎？免費註冊成為會員
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Google from './google/Btn.vue';
import { useRouter } from 'vue-router';

import { ref, inject } from 'vue';
import { ElFormItem, ElForm } from 'element-plus';
import Account from '@/components/form/Account.vue';

import Password from '@/components/form/Password.vue';

import useLogin from './useLogin';
import Prefer from '@/api/Prefer';
export default {
  components: {
    Google,
    ElFormItem,
    ElForm,

    Password,
    Account,
  },

  setup() {
    const { commit } = inject('store');

    const auth = inject('auth');
    const { fetcher: testerRecordFetcher } = inject('testerRecord');
    const router = useRouter();
    const formRef = ref(null);

    const toForgot = (params) => {
      commit('state', { status: 'isForgoting' });
    };
    const toSignup = () => {
      router.push({ name: 'Signup.basic' });
      commit('state', { status: false });
    };

    const { form, submit, loading } = useLogin();
    const onSubmit = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          const res = await submit();
          if (res.code === 1) {
            auth.setAuth(res.data);
            commit('state', { status: false });
            testerRecordFetcher();
            const preferRes = await Prefer.check();
            // 結果都回200
            if (preferRes.code === 1 && preferRes.status === 204) {
              router.push({ name: 'Preference' });
            }
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };

    return {
      loading,
      onSubmit,
      toSignup,
      toForgot,
      form,
      formRef,
    };
  },
};
</script>
<style lang="sass" scoped>
.picWrap
  justify-content: center
  align-items: center
  position: relative
  .pic-bg
    position: absolute
    left: 0
    top: 50%
    transform: translate(-50%, -50%)

  .pic-role
    position: relative
    z-index: 2
</style>
