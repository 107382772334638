import User from '@/api/User';
import { ElMessage } from 'element-plus';

import { ref, reactive, inject, computed } from 'vue';
import Regex from '@/utils/Regex';
export default () => {
  const loading = ref(false);

  const form = reactive({
    account:
      process.env.NODE_ENV !== 'development' ? '' : 'test002@yopmail.com',
    password: process.env.NODE_ENV !== 'development' ? '' : 'Aa0110test',
  });

  const submit = () => {
    loading.value = true;
    const _form = { password: form.password };

    if (Regex.email.test(form.account)) {
      _form.email = form.account;
    } else if (Regex.phone.test(form.account)) {
      _form.phone = form.account;
    } else {
      return 'error';
    }

    return User.login({ ..._form })
      .then((res) => {
        if (res.code === 1 && res.data.duplicate === '1') {
          ElMessage({
            message: '成功登入，並結束其他裝置的登入狀態',
            type: 'success',
          });
        } else if (res.code === 1) {
          ElMessage({ message: '成功登入', type: 'success' });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          ElMessage({ message: '帳號或密碼錯誤' });
        }
        return res;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return {
    loading,
    form,
    submit,

    // type,
  };
};
