import { ref, inject, computed } from 'vue';
import Tester from '@/api/Tester';

import { useRouter } from 'vue-router';
export default () => {
  const router = useRouter();

  const testId = ref(null);
  const fetcher = () => {
    Tester.check().then((res) => {
      if (res.code === 1) {
        testId.value = res.data.testId;
      }
    });
  };
  const update = (id) => {
    if (id === testId.value) return;
    testId.value = id;
  };
  const active = computed(() => {
    return !!testId.value;
  });

  const toTester = () => {
    if (!testId.value) return;
    router.push({
      name: 'Tester',
      params: { testId: testId.value },
    });
  };

  return { fetcher, testId, toTester, update, active };
};
