<template>
  <div
    class="text-info-4 font-bold flex-shrink-0 text-center"
    :style="{
      color: getScoreColor(Number(score)),
    }"
  >
    <div>
      <span class="text-3xl whitespace-nowrap"
        >{{ score || 0 }}<span class="text-sm">%</span></span
      >
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { getScoreColor } from '@/utils';

export default {
  props: {
    score: {
      type: [Number, String],
      default: 0,
    },
  },

  setup(props) {
    return { getScoreColor };
  },
};
</script>

<style lang="sass" scoped></style>
