<template>
  <El-Button round color="#EA4335" style="color: white" @click="getAuthCode">
    <img
      src="@/assets/imgs/google-icon.svg"
      alt=""
      class="inline-block mr-3 align-text-top mt-[-0.1em] w-[18px]"
    />GOOGLE</El-Button
  >
</template>
<script>
import { defineComponent, inject } from 'vue';

import useGoogle from './useGoogle';
let googleClient;
export default defineComponent({
  setup() {
    const { sign } = useGoogle();

    const callback = (response) => {
      sign({ ...response });
    };

    const isMobile = inject('isMobile');

    const opt = isMobile
      ? {
          ux_mode: 'redirect',
          redirect_uri: process.env.VUE_APP_GOOGLE_CB,
        }
      : {
          ux_mode: 'popup',
          redirect_uri: 'postmessage',
          callback: (response) => {
            callback(response);
          },
        };

    if (window.google) {
      googleClient = window.google.accounts.oauth2.initCodeClient({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/userinfo.profile',
        ...opt,
      });
    } else {
      // window.alert('Google 相關套件載入失敗');
    }

    const getAuthCode = () => {
      if (!googleClient) {
        window.alert('Google client 相關套件載入失敗');
        return;
      }
      googleClient.requestCode();
    };

    return { getAuthCode };
  },
});
</script>
