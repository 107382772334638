<template>
  <div v-loading="loading">
    <El-Autocomplete
      v-model="form.keyword"
      placeholder="請輸入關鍵字"
      :fetch-suggestions="onInput"
      :trigger-on-focus="false"
      :debounce="300"
      @select="onSelectResult"
    >
      <template #prepend>
        <el-select v-model="form.type" style="width: 115px" @change="reset">
          <el-option label="試卷" value="exam" />
          <el-option label="試題" value="question" />
        </el-select>
      </template>

      <template #default="{ item }">
        <div class="value">{{ item.name }}</div>
      </template>
      <template #append>
        <el-button
          v-bind="
            form.type !== 'exam'
              ? {
                  disabled: true,
                  class: 'opacity-30',
                }
              : {}
          "
          type="primary"
          @click="submit"
          >搜尋
        </el-button>
      </template>
    </El-Autocomplete>
  </div>
</template>
<script>
import { ref, reactive } from 'vue';
import { ElSelect, ElOption, ElAutocomplete } from 'element-plus';
// import Exam from '@/api/Exam.js';
import { useRouter } from 'vue-router';
import Question from '@/api/Question';
import Subject from '@/api/Subject.js';
import { ElMessage } from 'element-plus';
export default {
  components: { ElSelect, ElOption, ElAutocomplete },
  setup() {
    const loading = ref(false);
    const router = useRouter();
    const form = reactive({ type: 'question', keyword: '' });
    const onSelectResult = (row) => {
      if (!row.id) return;
      loading.value = true;
      const filterScopes = [
        {
          sbtKey: row.sbtId,
          [row.type]: row.id,
          keyword: form.keyword,
          qCnt: 250,
        },
      ];
      Subject.filterId({ filterScopes }).then((res) => {
        if (res.code === 1) {
          router.push({
            name: 'Exam.Question',
            params: { slug: 'sbj' },
            query: {
              filterId: res.data.filterId,
              type: 'sbj',
              redirectUri: encodeURIComponent(
                `/exam/sbj/filter?filterId=${res.data.filterId}&type=sbj`,
              ),
            },
          });
        } else {
          ElMessage('條件不存在');
        }
        form.keyword = '';
        loading.value = false;
      });
    };
    const onInput = (val, cb) => {
      if (form.type === 'exam' || (val && val.length < 2)) {
        cb([]);
      } else {
        Question.keyword({ keyword: form.keyword }).then((res) => {
          if (res.code === 1) {
            const rows = res.data.rows.map((x) => {
              return {
                ...x.classify,
                name: `${x.keyword}${x.classify.name}`,
                value: x.keyword,
              };
            });
            cb(rows);
          } else {
            cb([{ name: '搜尋不到試題' }]);
          }
        });
      }
    };
    const reset = () => {
      form.keyword = '';
    };
    const submit = () => {
      if (form.type === 'exam') {
        router.push({
          path: '/exam/past',
          name: 'Exam.List',
          params: {
            slug: 'past',
          },
          query: {
            type: 'past',
            keywordType: 'name',
            keyword: form.keyword,
          },
        });
      }
    };
    return { form, submit, onSelectResult, onInput, reset, loading };
  },
};
</script>
<style lang="scss"></style>
