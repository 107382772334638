<template>
  <Header v-if="$route.meta.header !== false" class="h-[64px]" />

  <div class="bodier h-0 min-h-screen pt-[64px] flex flex-col bg-background">
    <Router-View v-slot="{ Component }">
      <!-- :key="$route.fullPath" -->
      <Transition name="fade" mode="out-in">
        <Component :is="Component" />
      </Transition>
    </Router-View>
    <Footer v-if="$route.meta.footer !== false" class="mt-auto"></Footer>
  </div>
  <Signin />
</template>
<script>
import { onMounted, provide } from 'vue';

import Header from '@/views/_header/Index.vue';
import Footer from '@/views/_footer/Index.vue';
import { isMobile } from '@/utils';
import Signin from '@/views/_signin/Dialog.vue';
import { loadScript } from 'vue-plugin-load-script';
import useRecord from '@/modules/tester/record/useRecord.js';
import useDialog from '@/hook/useDialog';
import { ElDialog } from 'element-plus';
import Redeem from '@/modules/coupon/redeem/index.vue';
import usePermission from '@/modules/auth/usePermission';

import { useRoute } from 'vue-router';
export default {
  components: { Header, Signin, Footer, ElDialog, Redeem },
  setup() {
    loadScript('https://accounts.google.com/gsi/client');
    provide('isMobile', isMobile());

    const testerRecord = useRecord();
    testerRecord.fetcher();
    provide('testerRecord', testerRecord);

    // const dialogRedeem = useDialog();
    // provide('dialogRedeem', dialogRedeem);

    const permission = usePermission();
    provide('permission', permission);

    const route = useRoute();
    onMounted(() => {
      window.addEventListener('contextmenu', (e) => {
        if (
          route.meta?.noContextmenu === true ||
          e.target?.matches('.no-contextmenu')
        ) {
          e.preventDefault();
        }
      });
    });
    return {};
  },
};
</script>
