import Axios from './http';
const prefix = '/consumer/v1';
export default class Banner {
  static async list(params) {
    return Axios(prefix + '/bannerList', {
      method: 'get',
      responseType: 'json',
    });
  }
}
