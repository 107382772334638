import { ElMessageBox } from 'element-plus';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// import { Search } from '@element-plus/icons';

import { h } from 'vue';
import './style.sass';
import { icons } from '../../utils/index';

export default {
  // type : success / alert / error
  alert: (title = 'title', text = '', type = 'success', option) => {
    return ElMessageBox.alert(``, '', {
      message: h('div', null, [
        h('div', { class: 'text-2xl font-bold mt-2' }, title),
        h('div', { class: 'mt-3' }, text),
      ]),
      customClass: 'el-message--' + type,
      icon: h(FontAwesomeIcon, { icon: icons[type] }),
      center: true,
      ...option,
    });
  },

  // type : success / alert / error
  confirm: (title = 'title', text = '', type = 'alert', option) => {
    return ElMessageBox.confirm('', '', {
      message: h('div', null, [
        h('div', { class: 'text-2xl font-bold mt-2' }, title),
        h('div', { class: 'mt-3', style: { whiteSpace: 'pre-line' } }, text),
      ]),
      customClass: 'el-message--' + type,
      confirmButtonText: '確認',
      cancelButtonText: '取消',
      icon: h(FontAwesomeIcon, { icon: icons[type] }),
      center: true,
      ...option,
    });
  },
};
