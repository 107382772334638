import Storage from '@/utils/Storage';
import { reactive, computed } from 'vue';
export default () => {
  const storageKey = 'tour';
  const local = Storage.get(storageKey);

  const status = reactive({
    exam: true,
    sbj: true,

    assistant: true,
    analysisAll: true,
    analysisSub: true,
    ...local,
  });

  const update = (key) => {
    status[key] = false;
    Storage.set('tour', status);
  };
  const done = (key) => {
    status[key] = false;
    Storage.set('tour', status);
  };
  const check = (key) => {
    return status[key];
  };
  return { status, update, check, done };
};
