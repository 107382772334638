import Axios from './http';
const prefix = '/consumer/v1';
export default class Common {
  static async focus(params) {
    return Axios(prefix + '/focusList', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async popular(params) {
    return Axios(prefix + '/popularList', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async rule(params) {
    return Axios(prefix + '/rule', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
}
