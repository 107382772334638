<template>
  <div>
    <el-dialog
      v-model="show"
      custom-class="md:w-[94%] w-full max-w-[1060px]"
      :destroy-on-close="true"
    >
      <template v-if="state.status === 'isLogining'">
        <Login />
      </template>
      <template v-if="state.status === 'isForgoting'">
        <Forgot />
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { inject, computed } from 'vue';
import { ElDialog } from 'element-plus';
import Login from '@/modules/auth/login.vue';
import Forgot from '@/modules/auth/forgot/Index.vue';
export default {
  components: { Login, Forgot, ElDialog },

  setup() {
    const { state, commit } = inject('store');
    const show = computed({
      get() {
        return state.status === 'isLogining' || state.status === 'isForgoting';
      },
      set() {
        commit('state', { status: false });
      },
    });
    return { show, state };
  },
};
</script>

<style lang="sass" scoped>
:deep(.el-dialog__body)
 @apply overflow-hidden
</style>
