<template>
  <El-Form-item :label="label" prop="account" :rules="rules">
    <El-Input
      v-model="account"
      placeholder="請輸入登入Email或Phone"
      :disabled="disabled"
    ></El-Input>
  </El-Form-item>
</template>
<script>
import { ref, toRefs, reactive } from 'vue';
import { ElFormItem, ElInput } from 'element-plus';
import Regex from '@/utils/Regex';
import useComputed from '@/hook/useComputed';
export default {
  components: {
    ElFormItem,
    ElInput,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const account = useComputed(modelValue, emit);

    const isValid = ref(false);
    const type = ref('email');
    const rules = reactive([
      {
        validator: (rule, value, callback) => {
          isValid.value = false;
          if (!value) {
            callback(new Error('未填寫內容!'));
            return;
          }
          const isEmail = Regex.email.test(value);
          const isPhone = Regex.phone.test(value);

          if (isEmail) {
            type.value = 'email';
            isValid.value = true;
            callback();
            return;
          }

          if (isPhone) {
            type.value = 'phone';
            isValid.value = true;
            callback();
            return;
          }

          isValid.value = false;
          callback(new Error('請輸入正確的格式!'));
        },
        trigger: 'blur',
      },
    ]);

    return { isValid, rules, account, type };
  },
};
</script>
