import { computed } from 'vue';
export default (modelValue, emit, name) => {
  return computed({
    get() {
      return modelValue.value;
    },
    set(val) {
      emit(`update:${name ? name : 'modelValue'}`, val);
    },
  });
};
