import User from '@/api/User';
import { ElMessage } from 'element-plus';

import { ref, reactive } from 'vue';

export default () => {
  const loading = ref(false);

  const form = reactive({
    phone: process.env.NODE_ENV !== 'development' ? '' : '0938525966',
    code: process.env.NODE_ENV !== 'development' ? '' : 'ggg1234',
    password: '',
    confirmPassword: '',
  });

  const active = ref('');
  const setActive = (step) => {
    active.value = step;
  };

  const verify = () => {
    loading.value = true;
    return User.forgotCodeVerify({ phone: form.phone, code: form.code })
      .then((res) => {
        if (res.code === 1) {
          setActive('reset');
          ElMessage({ message: '成功', type: 'success' });
        } else {
          ElMessage('驗證碼錯誤');
        }
        return res;
      })

      .finally(() => {
        loading.value = false;
      });
  };

  const check = () => {
    loading.value = true;
    return User.passwordCheck({ phone: form.phone, password: form.password })
      .then((res) => {
        return res;
      })

      .finally(() => {
        loading.value = false;
      });
  };

  const reset = () => {
    loading.value = true;
    return User.passwordReset({
      phone: form.phone,
      code: form.code,
      password: form.password,
    })
      .then((res) => {
        if (res.code === 1) {
          setActive('success');
          ElMessage({ message: '成功', type: 'success' });
        } else {
          ElMessage(res.message);
        }
        return res;
      })
      .catch((err) => {
        ElMessage('密碼設定錯誤');
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return {
    form,
    loading,
    active,
    setActive,
    check,
    reset,
    verify,
  };
};
