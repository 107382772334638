import Axios from './http';
const prefix = '/consumer/v1';

export default class Payment {
  static async infos() {
    return Axios(prefix + '/payment/infos', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async types() {
    return Axios(prefix + '/payment/types', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async subs(params) {
    return Axios(prefix + '/payment/types', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  static async plan(params) {
    return Axios(prefix + '/payment/plan/' + params.typeId, {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async check(params) {
    return Axios(prefix + '/payment/checkout', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async purchase(params) {
    return Axios(prefix + '/payment/newebpay/purchase', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async freeCheckout(params) {
    return Axios(prefix + '/payment/freeCheckout', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async redeem(params) {
    return Axios(prefix + '/payment/redeem', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async redeemConfirm(params) {
    return Axios(prefix + '/payment/redeemConfirm', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async historyMembership() {
    return Axios(prefix + '/payment/history/membership', {
      method: 'get',
      responseType: 'json',
    });
  }

  static async nonAuthPurchase(params) {
    return Axios(prefix + '/payment/newebpay/nonAuth/purchase', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async nonAuthPlan(params) {
    return Axios(prefix + '/payment/nonAuth/plan/' + params.planId, {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async nonAuthCheck(params) {
    return Axios(prefix + '/payment/nonAuth/checkout', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async historyPay() {
    return Axios(prefix + '/payment/history/pay', {
      method: 'get',
      responseType: 'json',
    });
  }
}
