<template>
  <h2 class="text-3xl text-primary font-bold">修改密碼</h2>
  <p class="text-3xl font-bold mt-4 text-black">Hi XXX</p>
  <div v-loading="loading" class="">
    <El-Form ref="formRef" :model="form" class="mt-6">
      <p>請輸入新密碼</p>
      <Password
        v-model="form.password"
        :strong="true"
        :rules="rules['theSame']"
      />
      <p>再次輸入新密碼</p>

      <El-Form-Item prop="confirmPassword" :rules="rules['confirmPassword']">
        <El-Input
          v-model="form.confirmPassword"
          :show-password="true"
          type="password"
          autocomplete="off"
          placeholder="再次輸入新密碼"
        ></El-Input>
      </El-Form-Item>

      <El-Form-item>
        <el-button type="primary" round @click="onSubmit">修改密碼</el-button>
      </El-Form-item>
    </El-Form>
  </div>
</template>

<script>
import { ref, inject, defineComponent } from 'vue';
import { ElFormItem, ElForm, ElInput } from 'element-plus';

import Password from '@/components/form/Password.vue';
import _ from 'lodash';
export default defineComponent({
  components: {
    ElFormItem,
    ElForm,
    ElInput,
    Password,
  },

  setup() {
    const formRef = ref(null);
    const { reset, form, loading, check } = inject('forgot');

    const validateConfirmPass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('請再次填寫密碼'));
      } else if (value.trim() != form.password.trim()) {
        callback(new Error('密碼不一致'));
      } else {
        callback();
      }
    };

    const rules = {
      confirmPassword: [{ validator: validateConfirmPass, trigger: 'blur' }],
      theSame: [
        {
          validator: (rule, value, callback) => {
            check().then((res) => {
              if (res.code === 1) {
                callback();
              } else {
                // callback(new Error('密碼與上次設定重複'));
                callback(new Error('密碼與上次設定重複'));
              }
            });
          },
          trigger: 'blur',
        },
      ],
    };

    const onSubmit = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          reset();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };
    return { loading, onSubmit, form, rules, formRef };
  },
});
</script>
