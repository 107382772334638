<template>
  <footer class="bg-white py-16 md:py-8">
    <div class="container">
      <!-- pc menu -->
      <div class="flex justify-between relative md:hidden">
        <!-- menu -->
        <div class="flex space-x-12 footer-menu">
          <div
            v-for="(groupItem, g_index) in menu"
            :key="g_index"
            class="text-gray flex flex-col"
          >
            <!-- <div class="font-bold text-black">{{ groupItem.title }}</div> -->
            <!-- <ul class="mt-4 space-y-2">
              <li v-for="(item, index) in groupItem.list" :key="index">
                <router-link :to="item.path"> {{ item.text }}</router-link>
              </li>
            </ul> -->
            <el-collapse v-model="activeNames">
              <el-collapse-item :title="groupItem.title" :name="g_index">
                <ul class="mt-4 space-y-2">
                  <li
                    v-for="(item, index) in groupItem.list"
                    :key="index"
                    class="hover:text-primary duration-200 hover:pl-1"
                  >
                    <router-link
                      v-if="
                        item.path.indexOf('http') === -1 ||
                        item.path.indexOf('https') === -1
                      "
                      :to="item.path"
                    >
                      <font-awesome-icon v-if="item.icon" :icon="item.icon" />
                      {{ item.text }}
                    </router-link>
                    <a
                      v-else
                      :href="item.path"
                      target="
                    "
                    >
                      <font-awesome-icon
                        v-if="item.icon"
                        :icon="item.icon"
                        class="mr-1 text-[1.3em]"
                      />
                      {{ item.text }}
                    </a>
                  </li>
                </ul>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>

        <!-- info -->
        <div class="flex flex-col">
          <img
            src="@/assets/imgs/logo.png"
            alt=""
            srcset=""
            class="max-w-[230px]"
          />
          <p class="text-lg mt-2 md:text-xl md:mt-3">陪你闖關的好夥伴</p>

          <p class="text-gray text-sm md:absolute md:-bottom-8 mt-auto">
            版權所有 © 2022 宏典文化出版股份有限公司
          </p>
        </div>
      </div>

      <!-- moblie menu -->
      <div class="text-xl hidden md:block">
        <a href="https://www.facebook.com/jody.JOintstuDY/" target="_blank">
          <font-awesome-icon
            :icon="['fab', 'facebook-square']"
            class="mr-1 text-[1.3em]"
          />
          給我們建議
        </a>
        <p class="text-xl text-gray mt-8">
          版權所有 © 2022 宏典文化出版股份有限公司
        </p>
        <div class="text-black space-x-4 mt-4">
          <a
            v-for="(item, index) in menu[3].list"
            :key="index"
            :href="item.path"
            class="underline"
            >{{ item.text }}</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref, inject } from 'vue';
import { ElCollapse, ElCollapseItem } from 'element-plus';
export default {
  components: {
    ElCollapse,
    ElCollapseItem,
  },
  setup() {
    const menu = ref([
      {
        title: 'QB 題庫',
        list: [
          {
            text: '歷年試卷',
            path: '/exam/past/list',
          },
          // {
          //   text: '自組試卷',
          //   path: '/exam/self/list',
          // },
          {
            text: '按科目練習',
            path: '/exam/sbj/filter',
          },
        ],
      },
      {
        title: 'JODY 服務',
        list: [
          {
            text: '個人助理',
            path: '/assistant/home',
          },
          {
            text: '統計分析',
            path: '/analyze',
          },
          {
            text: '會員方案',
            path: '/shop/home',
          },
        ],
      },
      {
        title: '關於JODY ',
        list: [
          {
            text: '我們的故事',
            path: '/about',
          },
          {
            text: '最新消息',
            path: '/news',
          },
          {
            text: '常見問答',
            path: '/account/contact',
          },
          {
            icon: ['fab', 'facebook-square'],
            text: '聯絡我們',
            path: 'https://www.facebook.com/jody.JOintstuDY/',
          },
        ],
      },
      {
        title: '條款與聲明',
        list: [
          {
            text: '隱私權政策',
            path: '/privacy',
          },
          {
            text: '使用者條款',
            path: '/term',
          },
          {
            text: '免責聲明',
            path: '/copyright',
          },
          {
            text: '消費者權益須知',
            path: '/consumer',
          },
        ],
      },
    ]);

    const isMobile = inject('isMobile');
    const activeNames = ref(isMobile ? [] : [0, 1, 2, 3]);

    return { menu, activeNames, ElCollapse, ElCollapseItem };
  },
};
</script>

<style lang="sass" scoped>
:deep
  .footer-menu
    .el-collapse
      @apply border-t-0 md:border-t
    .el-collapse-item__wrap
      @apply border-b-0 md:border-b
    .el-collapse-item__header
      @apply text-lg font-bold
      .el-icon
        @apply ml-2
</style>
