<template>
  <Slider :rows="rows" :title="'即將考試'" :label="'Coming'"></Slider>
</template>
<script setup>
import Exam from '@/api/Exam';
import { reactive } from 'vue';

import Slider from './_Slider.vue';

const rows = reactive([]);

Exam.recommend({
  type: 'past',
  subType: 'coming',
}).then((res) => {
  if (res.code === 1) {
    res.data.rows = res.data.rows.map((x) => {
      return {
        ...x,
        route: {
          name: 'Exam.List',
          params: { slug: 'past' },
          query: { type: 'past', subType: 'coming' },
        },
      };
    });

    rows.splice(0, 0, ...res.data.rows);
  }
});
</script>
