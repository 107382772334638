<template>
  <div class="text-2xl font-bold text-center pb-3 border-b">啟用確認</div>
  <div class="flex flex-wrap w-full mt-4">
    <div class="rounded bg-background py-6 px-8 w-1/2 md:w-full">
      <div class="text-2xl text-primary font-bold">{{ row.title }}</div>
      <div class="mt-4 text-gray" v-html="row.desc"></div>
    </div>
    <div
      class="rounded bg-background-2 py-6 px-8 flex space-x-8 w-1/2 md:w-full"
    >
      <div class="w-full">
        <div class="font-bold">付款方式</div>
        <p class="text-gray mt-1">{{ row.payType }}</p>
        <div class="font-bold mt-4">啟用內容</div>
        <table class="info-table">
          <tr>
            <td>科目：</td>
            <td v-html="row.content.subject"></td>
          </tr>
          <tr>
            <td>使用天數：</td>
            <td>{{ row.content.amount }}天</td>
          </tr>
          <tr>
            <td>使用期間：</td>
            <td v-html="row.content.period"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="flex justify-center space-x-8 mt-6">
    <el-button type="text" @click="$emit('cancel')">返回修改</el-button>
    <el-button type="primary" class="w-32" @click="submit">送出</el-button>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue';

import { ElMessage } from 'element-plus';
import Payment from '@/api/Payment.js';
import { useRouter } from 'vue-router';
const props = defineProps({
  row: { type: Object, default: () => {} },
  code: { type: String, default: '' },
});
const router = useRouter();

const emit = defineEmits(['cancel', 'success']);
const loading = ref(false);
const submit = () => {
  loading.value = true;
  return Payment.redeemConfirm({ code: props.code })
    .then((res) => {
      if (res.data?.errorCode) {
        ElMessage({ type: 'error', message: res.data.message });
      } else {
        ElMessage({ type: 'success', message: '成功兌換折扣券' });

        router.push({
          name: 'Checkout.Waiting',
          query: { code: props.code },
        });
        emit('success');
      }
    })
    .finally((res) => {
      loading.value = false;
      return res;
    });
};
</script>

<style lang="sass" scoped>
.info-table
  tr
    td
      @apply align-text-top py-1
      &:first-child
        @apply w-32 mr-6
</style>
