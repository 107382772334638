// http.ts
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import { ElMessage } from 'element-plus';
import Storage from '@/utils/Storage';
import User from '@/api/User';
const showStatus = (status) => {
  let message = '';
  switch (status) {
    case 400:
      message = '請求錯誤(400)';
      break;
    case 401:
      message = '未授權，請重新登錄(401)';
      break;
    case 403:
      message = '拒絕訪問(403)';
      break;
    case 404:
      message = '請求出錯(404)';
      break;
    case 408:
      message = '請求超時(408)';
      break;
    case 500:
      message = '服務器錯誤(500)';
      break;
    case 501:
      message = '服務未實現(501)';
      break;
    case 502:
      message = '網絡錯誤(502)';
      break;
    case 503:
      message = '服務不可用(503)';
      break;
    case 504:
      message = '網絡超時(504)';
      break;
    case 505:
      message = 'HTTP版本不受支持(505)';
      break;
    default:
      message = `連接出錯(${status})!`;
  }
  return `${message}，請檢查網絡或聯繫管理員！`;
};

const pending = new Map();

const addPending = (config, add) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join('&');

  config.cancelToken = new axios.CancelToken((cancel) => {
    if (!pending.has(url)) {
      pending.set(url, cancel);
    }
  });
};

const removePending = (config, mt) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data),
  ].join('&');

  if (pending.has(url)) {
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};

export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};

const service = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    get: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  },
  // 是否跨站點訪問控制請求
  withCredentials: true,
  timeout: 30000,
  // transformRequest: [
  //   (data) => {
  //     data = JSON.stringify(data);
  //     return data;
  //   },
  // ],
  validateStatus() {
    // 使用async-await，處理reject情況較為繁瑣，所以全部返回resolve，在業務代碼中處理異常
    return true;
  },
  transformResponse: [
    (data) => {
      if (data && typeof data === 'string') {
        try {
          return JSON.parse(data);
        } catch (e) {
          return false;
        }
      }
    },
  ],
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = (token) => {
  refreshSubscribers.map((cb) => cb(token));
};
let alerting403 = false;
const handle403401Error = (status) => {
  if (alerting403) return;
  alerting403 = true;
  let message = '';
  if (status === 403) {
    ElMessage({ message: '您已經在別的地方登入，即將強制登出。' });
  } else if (status === 401) {
    ElMessage({ message: '會員登入已失效，請重新登入。' });
  }

  setTimeout(() => {
    window.auth.clear();
    window.location.reload();
  }, 1500);
};
const handle429Error = (status) => {
  ElMessage({
    message:
      '由於請求數量過多，我們暫時封鎖了您 IP 地址的流量。請稍後一分鐘再試。',
  });
};
const handle500Error = (status) => {
  window.auth.clear();
  window.location.reload();
};

const handle503Error = (status) => {
  window.auth.clear();
  window.location.reload();
};

const handle406Error = (status, config) => {
  const pendingRequest = config;

  if (!isRefreshing) {
    isRefreshing = true;

    User.refresh().then((res) => {
      if (res.data?.AuthKey) {
        const authKey = res.data.AuthKey;
        isRefreshing = false;
        Storage.set('AuthKey', authKey);
        onRrefreshed(authKey);
        refreshSubscribers = [];
      } else {
        // 先這樣  不知為何沒有進入https axios
        console.log('Token已經過期，請服務器異常，請聯繫管理員！');
        handle403401Error(403);
      }
    });
  }

  return new Promise((resolve) => {
    subscribeTokenRefresh((AuthKey) => {
      // replace the expired token and retry
      pendingRequest.headers.AuthKey = AuthKey;
      resolve(service(pendingRequest));
    });
  });
};

// 請求攔截器
service.interceptors.request.use(
  async (config) => {
    removePending(config, 'request:'); // 在請求開始前，對之前的請求做檢查取消操作
    addPending(config); // 將當前請求添加到 pending 中
    let token = Storage.get('AuthKey');
    if (token) {
      config.headers.AuthKey = token;
    }
    return config;
  },
  (error) => {
    // 錯誤拋到業務代碼
    error.data = {};
    error.data.msg = '服務器異常，請聯繫管理員！';
    return Promise.resolve(error);
  },
);

// 響應攔截器
service.interceptors.response.use(
  (response) => {
    removePending(response.config, 'response:'); // 在請求結束後，移除本次請求
    let status = response.status;

    let errors = '';

    if (status < 200 || status >= 300) {
      // 處理http錯誤，拋到業務代碼
      // 未登入狀態的4xx不處理
      if (status == 503) {
        return handle503Error(status);
      }
      if (status == 500) {
        return handle500Error(status);
      }

      if (status == 406) {
        return handle406Error(status, response.config);
      }
      if (status == 429) {
        return handle429Error(status);
      }

      if (window.auth.state.isAuth && (status == 403 || status == 401)) {
        handle403401Error(status);
      }

      errors = showStatus(status);

      if (typeof response.data === 'string') {
        response.data = { errors };
      } else {
        response.data.errors = errors;
      }
      response.data.code = 0;
    } else {
      if (!response.data) {
        response.data = {};
      }
      if (!response.data.data) {
        const data = response.data;
        response.data = { data };
      }

      response.data.code = 1;
    }
    response.data.status = status;

    return response.data;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log('repeated request: ' + error.message);
    } else {
      // handle error code
      // 錯誤拋到業務代碼
      error.data = {};
      error.data.msg = '請求超時或服務器異常，請檢查網絡或聯繫管理員！';
    }
    return Promise.reject(error);
  },
);

export default service;
