<template>
  <div>
    <div class="flex md:flex-col">
      <div class="w-1/2 md:w-full flex justify-center items-start">
        <img src="@/assets/imgs/index-role.png" alt class="md:w-1/2" />
      </div>
      <div class="w-1/2 md:w-full py-8 flex items-center px-8 md:px-0">
        <el-collapse v-model="active" class="w-full" :style="style">
          <el-collapse-item v-for="row in rows" :key="row.id" :name="row.id">
            <template #title>
              <div class="text-2xl text-primary py-4">{{ row.title }}</div>
            </template>
            <div class="text-lg pr-8">{{ row.text }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from 'vue';
import { ElCollapse, ElCollapseItem } from 'element-plus';
export default {
  components: { ElCollapse, ElCollapseItem },
  setup() {
    const style = ref({
      '--el-collapse-content-bg-color': 'transparent',
      '--el-collapse-header-bg-color': 'transparent',
      '--el-collapse-header-height': 'auto',
    });

    const rows = reactive([
      {
        id: '1',
        title: '設計千變萬化的試卷',
        text: '將海量題庫按「試卷」與「學科」分流，搭配功能強大的「超級篩選器」，助你十八般武藝樣樣精通!!',
      },
      {
        id: '2',
        title: '量身打造個人數據庫',
        text: '藉由強大的數據庫分析，精準地找出個人弱點，立即加強改善，建立自己銅牆鐵壁的堅強實力。',
      },
      {
        id: '3',
        title: '專業有品質的試題解析',
        text: '集結各科專業有經驗的專家，一一破解艱深難懂的試題，化繁為簡，帶給大家高品質的使用體驗。',
      },
    ]);
    const active = ref('1');
    return { rows, active, style };
  },
};
</script>
