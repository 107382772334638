const questions = (data) => {
  if (!data.dataType) {
    data.dataType = [];
  }

  if (data.subject) {
    data.dataType.push(
      ...data.subject?.map((x) => {
        return {
          ...x,
          prop: 'subject',
        };
      }),
    );
  }

  if (data.qType) {
    data.dataType.push(
      ...data.qType?.map((x) => {
        return {
          ...x,
          prop: 'qType',
        };
      }),
    );
  }

  return data;
};
export default { questions };
