<template>
  <el-menu
    ref="menu"
    :default-active="activeIndex"
    :ellipsis="false"
    class="border-0"
    :mode="mode"
  >
    <el-sub-menu
      index="qb"
      popper-class="wide-header-submenu"
      :class="{ 'is-active': activeIndex === 'qb' }"
    >
      <template #title>QB題庫</template>
      <div
        class="container flex justify-center p-8 divide-x w-full md:flex-col md:p-0 md:divide-x-0 md:divide-y"
      >
        <div class="header-sub-menu-item">
          <div class="sub-menu-item-title">按試卷練習</div>
          <div class="sub-menu-item-wrap">
            <div class="flex items-end">
              <img
                src="@/assets/imgs/menuRole1.png"
                alt=""
                class="sub-menu-item-img"
              />
              <div class="sub-menu-item-content text-left">
                <p>快速找到歷年考試的試卷</p>
                <div class="flex flex-col mt-4 items-start">
                  <div class="flex items-center justify-between w-full mb-2">
                    <div class="text-primary text-base flex-shrink-0 mr-2">
                      歷年試卷
                    </div>
                    <el-button
                      type="info"
                      class="!px-3"
                      @click="
                        $router.push({
                          name: 'Exam.List',
                          params: { slug: 'past' },
                        })
                      "
                      >立即開始</el-button
                    >
                  </div>
                  <div class="flex items-center justify-between w-full">
                    <div class="text-primary text-base flex-shrink-0 mr-2">
                      AI主題試卷
                    </div>
                    <el-button
                      type="info"
                      class="!px-3"
                      @click="goPath('/exam/topic/list')"
                      >立即開始</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="header-sub-menu-item">
          <div class="sub-menu-item-title">按科目練習</div>
          <div class="sub-menu-item-wrap">
            <div class="flex items-end">
              <img
                src="@/assets/imgs/menuRole3.png"
                alt=""
                class="sub-menu-item-img"
              />
              <div class="sub-menu-item-content">
                <p>依照科目篩選試題<br />針對特定科目加強特訓!</p>
                <el-menu-item
                  index="qb-subject"
                  @click="goPath('/exam/sbj/filter')"
                  ><el-button type="info" class=""
                    >立即開始</el-button
                  ></el-menu-item
                >
              </div>
            </div>
          </div>
        </div>

        <div class="header-sub-menu-item">
          <div class="sub-menu-item-title">個人助理</div>
          <div class="sub-menu-item-wrap">
            <div class="flex items-end">
              <img
                src="@/assets/imgs/menuRole4.png"
                alt=""
                class="sub-menu-item-img"
              />
              <div class="sub-menu-item-content">
                <p>
                  紀錄答題歷史、難題註解、<br />錯題整理、收藏最愛試卷<br />
                  考前衝刺看這些就對了！
                </p>
                <el-menu-item index="qb-assistant" @click="goPath('/assistant')"
                  ><el-button type="info" class=""
                    >立即開始</el-button
                  ></el-menu-item
                >
              </div>
            </div>
          </div>
        </div>
        <div class="header-sub-menu-item">
          <div class="sub-menu-item-title">統計分析</div>
          <div class="sub-menu-item-wrap">
            <div class="flex items-end">
              <img
                src="@/assets/imgs/menuRole5.png"
                alt=""
                class="sub-menu-item-img"
              />
              <div class="sub-menu-item-content">
                <p>
                  從各維度分析整體/各科實力<br />
                  利用數據分析鎖定弱點加強！
                </p>
                <el-menu-item index="qb-analyze" @click="goPath('/analyze')"
                  ><el-button type="info" class=""
                    >立即開始</el-button
                  ></el-menu-item
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-sub-menu>

    <el-sub-menu
      index="single"
      disabled
      popper-class="wide-header-submenu"
      :class="{ 'is-active': activeIndex === 'single' }"
    >
      <template #title>單科練習(coming)</template>
      <div
        class="container flex justify-center p-8 divide-x w-full md:flex-col md:p-0 md:divide-x-0 md:divide-y"
      >
        <div class="header-sub-menu-item">
          <div class="sub-menu-item-title">筆記</div>
          <div class="sub-menu-item-wrap">
            <div class="flex items-end">
              <img
                src="@/assets/imgs/menuRole1.png"
                alt=""
                class="sub-menu-item-img"
              />
              <div class="sub-menu-item-content">
                <p>
                  抓對重點<br />
                  省時、省力、無痛學習！
                </p>
                <el-menu-item index="single-note" @click="goPath('/note')"
                  ><el-button type="info" class=""
                    >立即開始</el-button
                  ></el-menu-item
                >
              </div>
            </div>
          </div>
        </div>

        <div class="header-sub-menu-item">
          <div class="sub-menu-item-title">按科目練習</div>
          <div class="sub-menu-item-wrap">
            <div class="flex items-end">
              <img
                src="@/assets/imgs/menuRole3.png"
                alt=""
                class="sub-menu-item-img"
              />
              <div class="sub-menu-item-content">
                <p>依照科目篩選試題<br />針對特定科目加強特訓!</p>
                <el-menu-item @click="goPath('/exam/sbj/filter')"
                  ><el-button type="info" class="" disabled
                    >立即開始</el-button
                  ></el-menu-item
                >
              </div>
            </div>
          </div>
        </div>

        <div class="header-sub-menu-item">
          <div class="sub-menu-item-title">個人助理</div>
          <div class="sub-menu-item-wrap">
            <div class="flex items-end">
              <img
                src="@/assets/imgs/menuRole4.png"
                alt=""
                class="sub-menu-item-img"
              />
              <div class="sub-menu-item-content">
                <p>
                  紀錄答題歷史、難題註解、<br />錯題整理、收藏最愛試卷<br />
                  考前衝刺看這些就對了！
                </p>
                <el-menu-item @click="goPath('/assistant')"
                  ><el-button type="info" class="" disabled
                    >立即開始</el-button
                  ></el-menu-item
                >
              </div>
            </div>
          </div>
        </div>
        <div class="header-sub-menu-item">
          <div class="sub-menu-item-title">統計分析</div>
          <div class="sub-menu-item-wrap">
            <div class="flex items-end">
              <img
                src="@/assets/imgs/menuRole5.png"
                alt=""
                class="sub-menu-item-img"
              />
              <div class="sub-menu-item-content">
                <p>
                  從各維度分析整體/各科實力<br />
                  利用數據分析鎖定弱點加強！
                </p>
                <el-menu-item @click="goPath('/analyze')"
                  ><el-button type="info" class="" disabled
                    >立即開始</el-button
                  ></el-menu-item
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-sub-menu>

    <el-menu-item
      index="shop"
      :class="{ 'is-active': activeIndex === 'shop' }"
      @click="goPath('/shop/home')"
      >會員方案</el-menu-item
    >
    <el-menu-item
      index="about"
      :class="{ 'is-active': activeIndex === 'about' }"
      @click="goPath('/about')"
      >關於我們</el-menu-item
    >
  </el-menu>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMenu, ElMenuItem, ElSubMenu, ElMessage } from 'element-plus';
// import BtnPreviewTester from '@/modules/question/BtnPreviewTester';
export default {
  components: {
    ElMenu,
    ElMenuItem,
    ElSubMenu,
    // BtnPreviewTester,
  },
  props: {
    mode: {
      type: String,
      default: 'horizontal',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const auth = inject('auth');
    const show = ref(false);
    const router = useRouter();
    const route = useRoute();
    const version = inject('version');

    const activeIndex = computed(() => {
      let active = '';
      let root = '';
      if (route && route.name) {
        root = route.name.split('.')[0];
        // routeName = route.name;
      }
      switch (root) {
        case 'Exam':
        case 'Assistant':
        case 'Analyze':
          active = 'qb';
          break;

        case 'Note':
          active = 'single';
          break;
        case 'Shop':
          active = 'shop';
          break;

        case 'About':
          active = 'about';
          break;
        // case 'News':
        //   暫時這樣用;
        //   active = 'news';
        default:
          break;
      }
      return active;
    });

    // const openMsg = (type) => {
    //   ElMessage({
    //     type: type,
    //     message: '成功登入',
    //   });
    // };

    const goPath = (path) => {
      if (route.path === path) router.go(0);
      router.push({ path: path });
      emit('close');
    };

    return {
      show,
      ElMenu,
      ElMenuItem,
      ElSubMenu,
      activeIndex,
      auth,
      // openMsg,
      goPath,
      version,
    };
  },
};
</script>

<style lang="sass">
.wide-header-submenu
  width: 100%!important
  .el-menu--popup
    width: 100%
.header-sub-menu-item
  @apply px-8 md:py-8 w-1/4 md:w-full
  .sub-menu-item-title
    @apply text-xl
  .sub-menu-item-wrap
    @apply flex items-end mt-2 min-h-[130px]
    .sub-menu-item-content
      @apply ml-6 pt-2
      p
        @apply leading-6 text-gray
      .el-menu-item
        @apply h-auto #{!important}
        @apply mt-4 px-0 hover:bg-transparent
        .el-button
          @apply px-8
</style>
<style lang="scss">
.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
  border-bottom: 2px solid var(--el-menu-active-color) !important;
  color: var(--el-menu-active-color);
}
.el-sub-menu.is-disabled .el-menu-item,
.el-sub-menu.is-disabled .el-sub-menu__title {
  opacity: 0.6 !important;
}
</style>
