<template>
  <div class="flex md:flex-col">
    <div class="w-1/2 md:w-full py-8 flex items-center px-8 md:px-0">
      <div>
        <div class="text-2xl">
          銀行工員甄試、金融證照新科目上線！歡迎免費體驗
        </div>
        <div class="mt-8 leading-loose">
          銀行工員甄試(金融常識/電腦常識/國文)、證券商普業、證券商高業、初階授信人員、信託業務員、理財規劃人員、高齡金融規劃顧問師、金融市場常識與職業道德、財產保險業務員、投信投顧法規
        </div>
        <div class="mt-8 text-primary font-bold">
          QB題庫/按科目練習/個人助理/統計分析
        </div>
        <ElButton
          v-if="!state.isAuth"
          type="primary"
          round
          class="mt-8 text-lg"
          @click="$router.push({ name: 'Signup.basic' })"
          >免費加入</ElButton
        >
      </div>
    </div>
    <div class="w-1/2 md:w-full flex justify-center items-start">
      <img src="@/assets/imgs/index-role2.png" alt class="md:w-1/2" />
    </div>
  </div>
</template>
<script>
import { inject } from 'vue';
export default {
  setup() {
    const { state } = inject('auth');
    return { state };
  },
};
</script>
