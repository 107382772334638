import MessageBox from '@/components/messageBox/Index';
import { useRouter } from 'vue-router';
import { inject } from 'vue';
export default () => {
  const auth = inject('auth');
  const router = useRouter();
  const { commit } = inject('store');
  const dialog = (status, message = '') => {
    return new Promise((resolve, reject) => {
      if (check(status)) {
        resolve(true);
        return;
      }

      if (!auth.state.isAuth) {
        commit('state', { status: 'isLogining' });
        return;
      }

      MessageBox.confirm(
        '每日銅板價，海量試題隨你刷！',
        message
          ? message
          : '1.沒有啟用任何方案。 \n 2.選擇的內容不包括在啟用方案中。',
        'alert',
        {
          showClose: true,
          distinguishCancelAndClose: true,
          confirmButtonText: '前往方案',
          cancelButtonText: '方案紀錄',

          callback: (params, instance) => {
            if (params === 'confirm') {
              router.push({ name: 'Shop' });
            } else if (params === 'cancel') {
              router.push({ name: 'Account.History' });
            }
            resolve(false);
          },
        },
      );
    });
  };

  const check = (status) => {
    let ok = false;
    if (status == 1 || status == -1) {
      ok = true;
    }
    return ok;
  };

  const status = (status) => {
    if (!auth.state.isAuth) {
      return true;
    }
    return !status;
  };

  return { check, status, dialog };
};
