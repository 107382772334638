import Axios from './http';
import _ from 'lodash';
import trans from './_trans';
const prefix = '/consumer/v1';

const recursiveId2Key = (arr) => {
  arr.forEach((x) => {
    x.key = x.id;
    delete x.id;
    if (x.chapter?.length) {
      recursiveId2Key(x.chapter);
    }
    if (x.section?.length) {
      recursiveId2Key(x.section);
    }
  });
};

export default class Subject {
  static async type(params) {
    return Axios(prefix + '/subjectType', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async all(params) {
    return Axios(prefix + '/subject', {
      method: 'get',
      responseType: 'json',
    });
  }
  static async list(params) {
    return Axios(prefix + '/subject/sbtKey/' + params.sbtKey, {
      method: 'get',
      responseType: 'json',
    });
  }
  static async scope(params) {
    return Axios(prefix + '/subjectScope/sbjKey/' + params.sbjKey, {
      method: 'get',
      responseType: 'json',
    });
  }

  static async filterId(params) {
    return Axios(prefix + '/subject/search', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async attributes(params) {
    delete params.type;
    return Axios(prefix + '/subject/attributes?sbjId=' + params.sbjId, {
      method: 'get',
      responseType: 'json',
      // params,
    });
  }
  static async result(params) {
    delete params.type;
    return Axios(prefix + '/subject/searchResult', {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      if (res.code === 1) {
        if (res.data.rows) {
          console.log('/subject/searchResult 資料重組');

          trans.questions(res.data);
        }
      }
      return res;
    });
  }
}
