<template>
  <div v-if="active" v-show="$route.name !== 'Tester'">
    <El-Button :type="'default'" :size="size" :icon="Edit" @click="submit">
      繼續上次測驗
    </El-Button>
  </div>
</template>

<script>
import { inject } from 'vue';

import { Edit } from '@element-plus/icons-vue';
import { ElButton } from 'element-plus';

import useConfirmMsg from '../useConfirmMsg.js';
export default {
  components: { ElButton },
  props: {
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  setup() {
    const { active, toTester } = inject('testerRecord');
    const submit = async () => {
      let confirm = await useConfirmMsg();
      if (confirm) {
        toTester();
      } else {
        return;
      }
    };

    return { submit, active, Edit };
  },
};
</script>
