export default {
  path: '/account',
  name: 'Account',
  meta: {
    footer: true,
    auth: true,
  },
  component: () =>
    import(/* webpackChunkName: "Account" */ '../views/account/Index.vue'),
  redirect: '/account/home',
  children: [
    {
      path: 'home',
      name: 'Account.Home',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Account.Home" */ '../views/account/Home.vue'
        ),
    },
    {
      path: 'resetEmail',
      name: 'Account.ResetEmail',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Account.ResetEmail" */ '../views/account/ResetEmail.vue'
        ),
    },
    {
      path: 'resetPassword',
      name: 'Account.ResetPassword',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Account.ResetPassword" */ '../views/account/ResetPassword.vue'
        ),
    },
    {
      path: 'history',
      name: 'Account.History',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Account.History" */ '../views/account/History.vue'
        ),
    },
    {
      path: 'billing',
      name: 'Account.Billing',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Account.Billing" */ '../views/account/Billing.vue'
        ),
    },
    {
      path: 'contact',
      name: 'Account.Contact',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Account.Contact" */ '../views/account/Contact.vue'
        ),
    },
    {
      path: 'coupon',
      name: 'Account.Coupon',
      meta: {
        footer: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "Account.Coupon" */ '../views/account/Coupon.vue'
        ),
    },
  ],
};
