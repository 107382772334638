export default {
  path: '/signup',
  name: 'Signup',
  component: () =>
    import(/* webpackChunkName: "Signup" */ '../views/signup/Index.vue'),
  meta: {},
  redirect: '/signup/basic',
  children: [
    {
      path: 'basic',
      name: 'Signup.basic',
      meta: { auth: false },
      component: () =>
        import(
          /* webpackChunkName: "Signup.basic" */ '../views/signup/Basic.vue'
        ),
    },
    {
      path: 'verify',
      name: 'Signup.verify',
      meta: { auth: false },
      component: () =>
        import(
          /* webpackChunkName: "Signup.verify" */ '../views/signup/Verify.vue'
        ),
    },
    {
      path: 'profile',
      name: 'Signup.profile',
      meta: { auth: true },
      component: () =>
        import(
          /* webpackChunkName: "Signup.profile" */ '../views/signup/Profile.vue'
        ),
    },
  ],
};
