import { reactive, ref } from 'vue';
import _ from 'lodash';
import Conf from '@/api/Conf';
import { CONFS } from '@/utils/Config';

//todo: 要移入到store
export const state = reactive({
  status: false, //網頁的狀態 正在幹嘛
  next: null,
  prev: null,
});
export const confOpts = reactive([]);

Conf.list().then((res) => {
  if (res.code === 1) {
    const data = [
      {
        id: '0',
        name: '未設定信心狀態',
      },
      ...res.data.rows,
    ];

    confOpts.push(
      ...data.map((x, i) => {
        return {
          icon: CONFS[x.id].icon,
          ...x,
        };
      }),
    );
  } else {
    confOpts.push(...CONFS);
  }
});

export const store = {
  state,
  confOpts,
};

export const commit = (mod, params) => {
  if (_.isEmpty(params)) {
    for (const prop of Object.getOwnPropertyNames(store[mod])) {
      delete store[mod][prop];
    }
    console.log('commit:clear:' + mod);
  } else {
    console.log('commit:' + mod, params);
    Object.assign(store[mod], params);
  }
};
