<template>
  <div>
    <div>
      <h2 class="text-xl">大家在關注什麼科目</h2>
      <div class="flex mt-8 flex-wrap">
        <a
          v-for="row in rows"
          :key="row.id"
          href="#"
          class="text-lg py-4 mb-4 mr-4 px-8 rounded-full shadow duration-300 hover:shadow-lg text-primary bg-white"
          @click.prevent="to(row.redirectUrl)"
        >
          {{ row.text }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Common from '@/api/Common';
import { reactive } from 'vue';
import qs from 'qs';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const rows = reactive([]);
    Common.focus().then((res) => {
      if (res.code === 1) {
        rows.splice(0, 0, ...res.data.rows);
      }
    });
    const router = useRouter();
    const to = (url) => {
      const urls = url.split('?');
      const path = urls[0];
      const query = qs.parse(urls[1]);
      console.log(query, path);
      router.push({
        path,
        query: {
          ...query,
          redirectUri: encodeURIComponent(
            `/exam/sbj/filter?filterId=${query.filterId}&type=sbj`
          ),
        },
      });
    };
    return { rows, to };
  },
};
</script>

<style lang="sass" scoped>
.forTaiwind
  @apply bg-info-1 bg-info-2 bg-info-3 bg-info-4
</style>
