<template>
  <div v-if="rows?.length" class="mb-4">
    <div class="flex items-center">
      <span class="bg-danger rounded-full text-white text-sm py-1 px-4 mr-3">{{
        label
      }}</span>
      <h2 class="text-xl">{{ title }}</h2>
    </div>
    <div class="mt-2">
      <Swiper
        class="pt-1 pb-6"
        :modules="modules"
        :slides-per-view="2"
        :slides-per-group="2"
        :space-between="20"
        :navigation="rows.length > 0"
        :breakpoints="{
          '768': {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          '1024': {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
        }"
        :loop="true"
        :pagination="{ clickable: true }"
      >
        <swiperSlide v-for="(row, index) in rows.slice(0, 20)" :key="row.id">
          <Card :row="row" :index="index"></Card>
        </swiperSlide>
      </Swiper>
    </div>
  </div>
</template>
<script>
import { inject } from 'vue';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper-bundle.css';
import Card from './_Card.vue';

export default {
  components: {
    Swiper,
    SwiperSlide,
    Card,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    const isMobile = inject('isMobile');
    return { modules: [Autoplay, Navigation] };
  },
};
</script>

<style lang="sass" scoped>
.forTaiwind
  @apply bg-info-1 bg-info-2 bg-info-3 bg-info-4
</style>
<style lang="scss" scoped>
:deep() {
  --swiper-navigation-size: 18px;
  .swiper-slide {
    height: auto;
  }
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;

    @apply duration-300 bg-info-4 text-white w-8 h-8 rounded-full shadow-lg border-opacity-10 border-black border;
  }
  .swiper-button-prev {
    left: 2px;
  }
  .swiper-button-next {
    right: 2px;
  }
}
</style>
