export const FILTER_NAME = {
  type: '模式',
  name: '名稱',
  key: 'ID',
  keyword: '關鍵字',
  cKey: '考試種類',
  sbtKey: '科目種類',
  sbtKeys: '科目種類',
  stKeys: '考試類科',
  sbjKeys: '考試科目',
  sbjKey: '考試科目',

  part: 'Part',
  chapter: '章',
  section: '節',

  act: '法規',
  actNo: '法規條款',
  reg: '命令/行政規則',
  regNo: '命令/行政規則 條款',
  ar: '行政函釋',
  prec: '司法解釋判例',
  judg: '司法解釋判決',
  acc: '會計公報',

  period: '選擇期間',
  exPeriod: '排除期間',
  score: '答題正確率',
  avgCorrectRate: '答題正確率',

  qCnt: '試卷題數',
  ansStatus: '作答結果',
  // qCnt: '試卷題數',
  conf: '信心狀態',
  confIndex: '信心狀態',

  attribute: '屬性',
  qType: '題型',

  upperScore: '答題正確率上限',
  lowerScore: '答題正確率下限',
  upperQcnt: '試卷題數上限',
  lowerQcnt: '試卷題數下限',
};
export const EXAM_TYPE_NAME = {
  past: '歷年試卷',
  topic: 'AI主題試卷',
  self: '自設試卷',
};

export const EXAM_TYPE_SLUG = {
  self: 'self',
  topic: 'topic',
  past: 'past',
  subject: 'sbj',
};
export const LIMIT = 250;
export const ANS_STATUS = {
  none: '未作答',
  correct: '正確答案',
  wrong: '錯誤答案',
};

export const CONFS = [
  { id: '0', name: '設定信心狀態', icon: require('../assets/confIcons/0.png') },
  { id: '1', name: '非常沒把握', icon: require('../assets/confIcons/1.png') },
  { id: '2', name: '一知半解', icon: require('../assets/confIcons/2.png') },
  { id: '3', name: '信心滿滿', icon: require('../assets/confIcons/3.png') },
];
export const MODES = [
  {
    id: 'p1',
    size: 1,
    name: '每頁1題',
  },
  {
    id: 'p5',
    size: 5,
    name: '每頁5題',
  },
  {
    id: 'p20',
    size: 20,
    name: '每頁20題',
  },
  {
    id: 'p50',
    size: 50,
    name: '每頁50題',
  },
];
export const COUPON_LIMIT = 4;
export const PACKAGE_NAMES = {
  QB: { name: 'QB吃到飽', color: '#c95eff' },
  'QB-Spec': { name: 'QB題庫-單科', color: '#c95eff' },
  Spec: { name: '單科學習', color: '#c95eff' },
  // 'Free-Trial': { name: '免費試用', color: '#c95eff' },
  //
  UnPaid: { name: '一般會員', color: '#c95eff' },
  Normal: { name: '未付費會員', color: '#c95eff' },
  'Free-QB': { name: '免費試用會員', color: '#c95eff' },
  'Paid-QB': { name: '付費會員-QB吃到飽', color: '#c95eff' },
  Guest: { name: '訪客', color: '#c95eff' },
  'Paid-Spec': { name: '付費會員-單科學習', color: '#c95eff' },
  'QB-Free': { name: '免費試用會員', color: '#c95eff' },
};

export default {
  FILTER_NAME,
  EXAM_TYPE_NAME,
  EXAM_TYPE_SLUG,
  MODES,
  CONFS,
  PACKAGE_NAMES,
};
