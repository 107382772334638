<template>
  <El-Form-item :rules="rules" prop="phone">
    <div class="flex space-x-2">
      <El-Input
        v-model="phone"
        type="tel"
        placeholder="請輸入手機號碼"
        :disabled="disabled"
      >
        <template #suffix>
          <div class="h-full">
            <font-awesome-icon
              v-if="isValid"
              class="text-primary"
              :icon="['fas', 'check']"
            />
          </div>
        </template>
      </El-Input>
      <slot name="suffix" :phone="phone" :valid="isValid"></slot>
    </div>
  </El-Form-item>
</template>
<script>
import { ref, toRefs } from 'vue';
import { ElFormItem, ElInput } from 'element-plus';
import Regex from '@/utils/Regex';
import useComputed from '@/hook/useComputed';
import User from '@/api/User.js';
export default {
  components: {
    ElFormItem,
    ElInput,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: String,
      default: '',
    },
    check: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, check } = toRefs(props);
    const phone = useComputed(modelValue, emit);
    const isValid = ref(false);

    const rules = [
      {
        validator: async (rule, value, callback) => {
          isValid.value = false;
          if (!value) {
            callback(new Error('未填寫內容!'));
            return;
          } else if (value.length < 10) {
            callback(new Error('請輸入正確的手機格式!'));
            return;
          } else if (!Regex.phone.test(value)) {
            callback(new Error('請輸入正確的手機格式!'));
            return;
          }
          if (check.value) {
            const res = await User.checkPhone({ phone: value });

            if (res.data?.errorCode) {
              callback(new Error('號碼已經被使用!'));
              return;
            }
          }
          isValid.value = true;
          callback();
        },
        trigger: 'blur',
      },
    ];
    const loading = ref(false);

    return { loading, isValid, rules, phone };
  },
};
</script>
