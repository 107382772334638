import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import _ from 'lodash';
import qs from 'qs';
dayjs.extend(duration);

const formatSec = (sec) => {
  return dayjs.duration(sec, 'seconds').format('HH:mm:ss');
};

const addZero = (num) => {
  return ('0' + num).slice(-2);
};

const getJoinString = (objArr, key, spliter = ' / ') => {
  return _.isArray(objArr) && objArr.map((x) => x[key]).join(spliter);
};

const getScoreColor = (score) => {
  if (score >= 80) {
    return 'var(--el-color-success)';
  } else if (score >= 60) {
    return 'var(--el-color-info)';
  } else if (score >= 20) {
    return 'var(--el-color-alert)';
  } else {
    return 'var(--el-color-error)';
  }
};

const getProgressColors = () => [
  { color: 'var(--el-color-error)', percentage: 20 },
  { color: 'var(--el-color-alert)', percentage: 60 },
  { color: 'var(--el-color-info)', percentage: 80 },
  { color: 'var(--el-color-success)', percentage: 100 },
];

const getTestTypeColor = (type) => {
  let _color = '';

  switch (type.toString()) {
    case '1':
      _color = 'var(--el-color-info)';
      break;
    case '2':
      _color = 'var(--el-color-info-2)';
      break;
    case '3':
      _color = 'var(--el-color-info-3)';
      break;
    case '4':
    case '5':
    case '6':
    case '7':
      _color = 'var(--el-color-info-4)';
      break;
  }

  return _color;
};
const isEmpty = (v) => {
  return !_.isNumber(v) && _.isEmpty(v);
};

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

const grouper = (rows) => {
  let gid = null;
  rows.forEach((x, i) => {
    x.groupPos = null;
    if (x.groupId !== gid) {
      gid = null;
      if (x.groupId !== 0) {
        x.groupPos = 'start';
        gid = x.groupId;
      }
    } else {
      x.groupPos =
        !rows[i + 1] || x.groupId !== rows[i + 1].groupId ? 'end' : 'mid';
    }
  });
};

const icons = {
  success: ['fas', 'check-circle'],
  alert: ['fas', 'exclamation-circle'],
  error: ['fas', 'exclamation-triangle'],
};

const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', { month: 'short' });
};

const isNoPoints = (_name) => {
  const noPointsArr = ['送分題', '填充', '簡答', '申論', '計算', '其他'];
  return noPointsArr.includes(_name);
};
const isNoPointsByResult = (result) => {
  return _.isArray(result);
};
const dateRange2String = (start, end) => {
  let str =
    !start || !end
      ? ''
      : start.replaceAll('-', '') + '-' + end.replaceAll('-', '');

  return str;
};
const secondsToTime = (e) => {
  const h = Math.floor(e / 3600)
    .toString()
    .padStart(2, '0');

  const m = Math.floor((e % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const s = Math.floor(e % 60)
    .toString()
    .padStart(2, '0');

  return h + ':' + m + ':' + s;
  //return `${h}:${m}:${s}`;
};

const removePercentSign = (val) => {
  return _.replace(val, '%', '');
};
const uri2Route = (uri) => {
  const uris = uri.split('?');
  const path = uris[0];
  const query = qs.parse(uris[1]);
  return {
    path,
    query,
  };
};
const postForm = (path, params, method = 'post') => {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};
// const disabledStartDate = (start) => {
//   return dayjs(start).isBefore(dayjs(), 'date');
// };

// const disabledEndDate = (start, end) => {
//   return (
//     dayjs(end).isBefore(dayjs(), 'date') ||
//     dayjs(end).isBefore(dayjs(start))
//   );
// };
// const checkTimeStart = (start, end) => {
//   return dayjs(end).isBefore(dayjs(start))
// };
export {
  isEmpty,
  //數字補零
  addZero,
  // 陣列用 '/' 串成字串
  getJoinString,
  // 拿分數代表的顏色
  getScoreColor,
  // 拿題目類型代表的顏色
  getTestTypeColor,
  // 把秒數轉換乘 'HH:mm:ss'的格式
  formatSec,
  // 判斷是不是手機
  isMobile,
  //添加群組題的位置屬性 start mid end
  grouper,
  getProgressColors,
  icons,
  getMonthName,
  isNoPoints,
  isNoPointsByResult,
  dateRange2String,
  secondsToTime,
  removePercentSign,
  uri2Route,
  postForm,
};
